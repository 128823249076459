import './return-portal.scss';
import React, { useState } from "react";
import { CUSTOMER_LOGIN } from "../constants";
import { useNavigate } from "react-router-dom";
import { Alert, FormControl, OutlinedInput, Snackbar } from '@mui/material';

function Loginportal(data1) {
  let data = data1.data;

  const [errorMessages, setErrorMessages] = useState({});
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [authenticationId, setAuthentication] = useState('');
  const [email, setEmailValue] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [zipcode,setZipcode] = useState('');
  const [otp, setOtpValue] = useState('');
  const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [emailValid, setEmailValid] = useState(false);
  const [tryWithOrderNumber,setTryWithOrderNumber] = useState(true)
  const [tryWithEmail, setTryWithEmail] = useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [zipcodenotvalid, setZipcodeNotValid] = useState(false);
  const [snackbarProp, setSnackbarProp] = useState({});
  
  let navigate = useNavigate();
  sessionStorage.removeItem('returnItemData');
  sessionStorage.removeItem('selectedReasonData');
  sessionStorage.removeItem('selectedDummyReasonData');
  sessionStorage.removeItem('isSelectedLineItems');
  sessionStorage.removeItem('RPI');
  sessionStorage.removeItem('email');

const emailChange = (event) => {
  setEmailValue(event.target.value);
  if(event.target?.value && isValidEmail.test(event.target?.value)){
    setEmailValid(true);
  }else{
    setEmailValid(false);
  }
  setErrorMessages({ name: "email", message: null });
}

const orderNumberInfo = (event)=>{
  let value = event.target.value;
  setOrderNumber(value.replace(/\s/g, ''));
  setErrorMessages({ name: "orderNumber", message: null });
}

const zipcodeInfo = (event) =>{
  const onlyNums = event.target.value.replace(/[^0-9]/g, '');
  let value = onlyNums;
  setZipcode(value.replace(/\s/g,''));
  setErrorMessages({ name: "zipcode", message: null });
  if(value.length < 5 || value === '00000'){
   setZipcodeNotValid(false);
  }
  else{
    setZipcodeNotValid(true)
  }

}

  const otpChange = (event) => {
    if(event.target.value[0] !== '-'){
    setOtpValue(event.target.value.replace(/[^a-zA-Z0-9-]/g, ''));
    setErrorMessages({ name: "otp", message: null });
    }
  };

  const otpKeyDown = (event) => {
    if(event.key === '-' && event.keyCode !== 8 ? otp.indexOf('-') > -1 :false){
       event.preventDefault()
      } 
  }

  const errors = {
    email: "Invalid Email",
    otp: "Invalid OTP",
  };

  const sendEmail = async (event) => {
    setOtpValue('');
    event.preventDefault();
    try{
    const response = await fetch(CUSTOMER_LOGIN.LOG_IN_URL, {
      method: 'POST',
      body: {},
      headers: {
        'Content-Type': 'application/json',
        'email_id': email,
        'X-Fenix-Store-Name': data.storeName
      }
    });
    
    const result = await response.json();
    if (response?.status === 200) {
      setIsSendOtp(true);
      setSnackOpen(true);
      setSnackbarProp({style:'success',msg:result?.message});
      setAuthentication(result.session_id);
    } else {
      setSnackOpen(true);
      setSnackbarProp({style:'error',msg:'Failed, send OTP to your Email'});
      setErrorMessages({ name: "email", message: errors.email });
    }
  } catch (error) {
    setSnackOpen(true);
    setSnackbarProp({style:'error',msg:'Network error'});
   }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setSnackOpen(false);
};

  const handlesubmit = async (event) => {

    event.preventDefault();
    try{
    const response = await fetch(CUSTOMER_LOGIN.VALIDATE_OTP+'?X-Fenix-Session-ID='+authenticationId+'&otp='+otp, {
      method: 'POST',
      body: {},
      headers: {
        'Content-Type': 'application/json',
        'X-Fenix-Store-Name': data.storeName,
      }
    });
    
    if (response?.status === 200) {
      sessionStorage.setItem('email', JSON.stringify(email));
      sessionStorage.setItem('tenantId', JSON.stringify(data.tenantId));
      // let data2 = JSON.stringify(data.type);
      let data2 = '/my-orders'        
      navigate(data2.replace(/"/g, ""));
    } else if(response?.status === 400){
      const result = await response.json();
      result.description = result?.description === '[INVALID OTP PLEASE PROVIDE VALID OTP]' ? 'Invalid OTP. Please provide a valid OTP' :(result?.description === '[OTP ALREADY EXPIRED PLEASE RESEND OTP]' ? 'OTP has already expired. Please resend the OTP' : result?.description)
      // setSnackOpen(true);
      // setSnackbarProp({style:'error',msg:result?.description});
      setErrorMessages({ name: "otp", message:result?.description });
    }
   } catch (error) {
    setSnackOpen(true);
    setSnackbarProp({style:'error',msg:'Network error.'});
   }
  };


  const sendOrderData = async(event) =>{
    event.preventDefault();
    sessionStorage.setItem('tenantId', JSON.stringify(data.tenantId));
      let  orderInfo = {
           "orderNumber" : orderNumber,
           "zipcode": zipcode
      }
    sessionStorage.setItem('orderInfo',JSON.stringify(orderInfo))
    
    // let data2 = JSON.stringify(data.type);
    let data2 = '/my-orders';
    // navigate(data2.replace(/"/g, ""));
      navigate(
      {
        pathname: data2.replace(/"/g, ""),
        search: '?ordernumber='+encodeURIComponent(orderNumber)+'&zipcode='+zipcode,
      });
  }

  //  const anotherMail = () =>{
  //   setIsSendOtp(false);
  //   setEmailValue('');
  //  }

   const trywithemail = (event)=>{
    event.preventDefault();
    setTryWithOrderNumber(false);
    setTryWithEmail(true);
    setZipcode("");
    setOrderNumber("");
    setIsSendOtp(false);
    setEmailValid(false);
   }

   const trywithorderNumber = (event)=>{
    event.preventDefault();
    setTryWithEmail(false);
    setTryWithOrderNumber(true);
    setEmailValue("")
   }

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <span className="f-12 mt-5px error-color">{errorMessages.message}</span>
    );
   
  const renderForm = (
    <div className="container return-portal-wrapper p-16 pb-0">
      <div className="row justify-content-center">
        <div  className="col-sm-12 col-md-12 col-12 col-xl-12 order-last order-md-first">
        <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xs-12 img-wrap">
          <img className="mb-16 img-hgt" src={require(`../assets/images/${data.login_img}`)} alt='ParcelDelivery' />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xs-12 right-wrap order-first order-md-last">
          <div className="br-1 mb-16">
            <div className="p-16">
              <h3 className="text-center fw-700 f-30 mt-2rem mb-0">Return Portal</h3>
              {tryWithOrderNumber ?  <div className='p-16'> <form>
                  {/* <div className="form-controler input-container mb-10">
                    <input type="text" name="ordernumber" className="full-width p-10 br-5" value={orderNumber} onChange={orderNumberInfo} maxLength={16}  required/>
                    </div> */}
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <div className="text-start mb-5px required">Order Number</div>
                                        <OutlinedInput className={`full-width p-10 fw-500 br-5 login-border f-14 ${data.type}`} sx={{'& ::placeholder':{fontSize:'12px'}}} placeholder={data.type === 'janieandjack' ? 'A0XXXXXXX or JJ0XXXXXX':'Enter order number'}
                                            type="text" name="ordernumber"  value={orderNumber} onChange={orderNumberInfo} inputProps={{ maxLength: 16 }} required
                                            label="ordernumber"/>
                                            {/* {renderErrorMessage("orderNumber")} */}
                                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                    <div className="text-start mb-5px mt-10 required">Shipping Zip Code</div>
                                     <OutlinedInput type="text" name="zipcode" placeholder={data.type === 'janieandjack' ? 'xxxxx' : 'Ènter zip code'} sx={{'& ::placeholder':{fontSize:'12px'}}} className={`full-width p-10 fw-500 br-5 login-border f-14 ${data.type}`} value={zipcode} onChange={zipcodeInfo} inputProps= {{maxLength: 5}} required
                                            label="zipcode"/>
                                    </FormControl>
                                            {/* {renderErrorMessage("orderNumber")} */}
                  {/* <div className="form-controler input-container mb-10">
                    <input type="text" name="zipcode" className="full-width p-10 br-5" value={zipcode} onChange={zipcodeInfo} maxLength={10} required/>
                    {renderErrorMessage("zipcode")}
                  </div> */}
                  <div className="mt-10">
                    <button onClick={sendOrderData} disabled={!orderNumber || !zipcode || !zipcodenotvalid} className={`full-width btn-res ${data?.type ? data.type+'-btn':''} ${data?.type}textcolor f-16  border-none p-10 br-5 fw-700 ${(!orderNumber || !zipcode || !zipcodenotvalid)? 'cursor-not-allowed op-5': 'cursor-pointer'}`}>Get Order</button>
                  </div>
                </form>
                </div>:
                <div>
                    <p className="f-14 fw-600 text-center mt-10 mb-0">{!isSendOtp ? 'You will receive an email with verification code.' : 'Enter the verification code sent to your email'}</p>
              {!isSendOtp ? <div className="form p-16">
                <form>
                  {/* <div className="form-controler input-container mt-10 mb-20">
                    <input type="email" name="email" className="full-width p-10 br-5" autoComplete='' value={email} onChange={emailChange} required/>
                  </div> */}
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <div className="text-start mb-5px required">Email Id</div>
                                     <OutlinedInput className={`full-width p-10 fw-500 br-5 login-border f-14 ${data.type}`} sx={{'& ::placeholder':{fontSize:'12px'}}} type="email" name="email" placeholder="Enter email" value={email} onChange={emailChange} required
                                            label="email"/>
                                      {renderErrorMessage("email")}
                                    </FormControl>
                  <div className="mt-20">
                    <button onClick={sendEmail} disabled={!emailValid} className={`full-width btn-res ${data?.type ? data.type+'-btn':''} ${data?.type} textcolor f-16  border-none p-10 br-5 fw-700 ${(!emailValid)? 'cursor-not-allowed op-5': 'cursor-pointer'}`}>Send One-Time Password</button>
                  </div>
                </form>
              </div> :
                <div className="form p-16">
                  <form >
                    {/* <div className="form-controler input-container mt-10 mb-16">
                      <input type="text" className="full-width p-16 br-10" value={otp} onChange={otpChange} />
                    </div> */}
                    <FormControl fullWidth sx={{ m: 1 }}>
                    <div className="text-start mb-5px required">Enter OTP</div>
                                     <OutlinedInput className={`full-width p-16 br-5 fw-500 login-border f-14 ${data.type}`} type="text" sx={{'& ::placeholder':{fontSize:'12px'}}} placeholder='Enter One-Time Password' name="otp" value={otp} 
                                     onKeyDown={otpKeyDown} onChange={otpChange} inputProps= {{maxLength: 10}}  required label="otp"/>
                                            {renderErrorMessage("otp")}
                                    </FormControl>
                    <div className="warning-color mt-10 mb-10 text-center">
                      <span className='cursor-pointer fw-600 f-14' onClick={sendEmail}>Resend Code</span>
                    </div>
                    <div className="">
                      <button onClick={handlesubmit} disabled={!otp} className={`full-width ${data?.type} textcolor btn-res ${data?.type ? data.type+'-btn':''} f-16  border-none br-5 p-10 fw-700`}>Verify</button>
                    </div>
                  </form>
                  {/* <div className="text-center mt-10">
                   <a href='login' onClick={anotherMail} className="primary-color fw-500">Try with another email</a>
              </div> */}
                </div>}
                </div>  
                }
              {tryWithOrderNumber === true?
              <div className='text-center br-10 mb-10'>
                <a href="/" className="primary-color fw-500 cursor-pointer" onClick={trywithemail} >Login with Email</a>
              </div>:""
              }
              {tryWithEmail === true ?<div className='text-center br-10 mb-10'>
                <a href="/" className="primary-color fw-500 cursor-pointer" onClick={trywithorderNumber} >Login with Order Number</a>
              </div>:""}

              <div className="text-center br-10 mb-20">
                <a href={data?.policyUrl} target='_blank' className="primary-color fw-500 cursor-pointer"
                rel="noopener noreferrer"> Return Policy</a>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  );
  return (
    <div>
      { renderForm}
      <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
            <Alert
            onClose={handleClose}
            severity={snackbarProp.style} 
            variant="filled"
            sx={{ width: '100%' }}
          >{snackbarProp.msg} 
          </Alert></Snackbar>
    </div>
    
  );
}

export default Loginportal;