// import { PRODUCT_RECOMMENDED } from "../constants";
import './best-sellers.scss';
// import React, { useEffect, useState } from "react";
// import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
// import PanoramaFishEyeRoundedIcon from '@mui/icons-material/PanoramaFishEyeRounded';

function BestSellers(data1){
    let data = data1.data;
    // const [recommended_Products, setRecommendedProducts] = useState([]);
    // const [iTestData, setITestData] = useState(0);
    // const [testDatashown, setTestDataShown] = useState([]);
    // eslint-disable-next-line
    // const [prevData, setPrevData] = useState([]);
    // const [isPreviousClicked, setIsPreviousCliked] = useState(false);

    //useEffect(()=>{
        // productRecommendation();
                    //eslint-disable-next-line
    //}, [])
    // const productRecommendation = async() =>{
    //     const response = await  fetch(PRODUCT_RECOMMENDED.RECOMMEDNDED_PRODUCTS , {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'tenant_id': '5075a60d701746ca80de05c8bd4b253d',
    //         } 
    //     });
    //     const recommendedProducts = await response.json();
    //     setTestDataShown(recommendedProducts.slice(iTestData * 4, (iTestData + 1) * 4));
    //     if(recommendedProducts){
    //         recommendedProducts[0].image_url = 'travelpro/medium-luggage.png';
    //         recommendedProducts[0].title = "Maxlite® Air Medium";
    //         recommendedProducts[0].price ="$ 186.99";
    //         recommendedProducts[1].image_url = 'travelpro/medium-luggage-01.png';
    //         recommendedProducts[1].title = "Platinum® Elite Medium";
    //         recommendedProducts[1].price ="$ 343.99";
    //         recommendedProducts[2].image_url = 'travelpro/maxlite-dropbottom.png';
    //         recommendedProducts[2].title = "Maxlit Drop-Bottom";
    //         recommendedProducts[2].price ="$ 101.99";
    //         recommendedProducts[3].image_url = 'travelpro/medium-topload-03.png';
    //         recommendedProducts[3].title = "Maxlite® Laptop Backpack";
    //         recommendedProducts[3].price ="$ 101.32";
    //         setRecommendedProducts(recommendedProducts);
    //     }else{
    //         setRecommendedProducts([]);
    //    }
    // }

//   const setShownData = (data) => {
//     setTestDataShown(data.slice(iTestData * 4, (iTestData + 1) * 4));
//       }

    // const  previous = () => {
    //     if (iTestData !== 0) {
    //       setITestData(iTestData - 1);
    //       setPrevData(JSON.parse(JSON.stringify(recommended_Products)))
    //       setShownData(recommended_Products);
    //       setIsPreviousCliked(true);
    //     }
    //   }
    
    //  const  next = () => {
    //     if ((0) < recommended_Products.length) {
    //         setITestData(1);
    //         setPrevData(JSON.parse(JSON.stringify(recommended_Products)));
    //       setShownData(recommended_Products);
    //       setIsPreviousCliked(false);
    //     }
    //   }
    

    const renderForm = (
            <div className="container">
                <div className="row p-16 pb-0 mt-16 full-width">
                    <div className="col-sm-12 col-md-12 col-12 col-lg-12">
                        <p className="fw-700 default-color f-18 text-center ml-24">Best Sellers</p>
                    </div>
                    {/* <div className="col-sm-2 col-md-2 col-3 col-lg-6 col-xl-6 text-right">
                        <p className="f-14  see-all fw-500 pr-see-all mb-0">See all &#62; </p>
                    </div> */}
                </div>
                <div className="container">
                    <div className="row mb-16 pl-row">
                        {data.recommendedProducts?(data.recommendedProducts?.map((products,i)=> (
                            <div className="col-sm-12 col-md-3 col-12 p-16" key={i}>
                            <div className="text-center">
                                <img  className="img-dimen" src={require(`../assets/images/${products.image_url}`)} alt='Product' />
                            </div>
                            <div className="row mt-2rem ml-10">
                                <div className="col-sm-9 col-md-12 col-lg-9 col-xl-8 col align-center">
                                    <div className="fw-500 f-13 grey-8-color">{products.title}</div>
                                </div>
                                <div className="col-sm-3 col-md-12 col-lg-3 col-xl-2 col text-center">
                                    <div className="bg-color w-h grey-8-color fw-600 p-8 f-12">{products.price}</div>
                                </div>
                            </div>
                        </div>

                     ))): ''}
                      {/* <div className='f-14 fw-500 text-center'>No Recommended Products Found</div> */}
                        <div className="col-sm-12 col-md-1 col-12 p-16"></div>
                    </div>
                    {/* {recommended_Products.length > 4 ?
                    <div className="text-center">
                     <span className="controls-top previous cursor-pointer" onClick={previous}>
                        {isPreviousClicked ? <CircleRoundedIcon/> : <PanoramaFishEyeRoundedIcon/>}
                     </span>
                     <span className="next cursor-pointer" onClick={next}>
                     {!isPreviousClicked ? <CircleRoundedIcon/> : <PanoramaFishEyeRoundedIcon/>}
                   </span>
                 </div> : ''} */}
                   
                </div>
            </div>
    );
    return (
        <div>
         {renderForm}
        </div>
       );
    }
 
export default BestSellers;