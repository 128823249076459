// import { BrowserRouter as  Router, Routes, Route, Navigate } from 'react-router-dom';
// import React, { useEffect, useState } from 'react';
// import Loginportal from './return portal/return-portal';
import JanieandJack from './jakieandjack/jakieandjack';
import ShopSimon from './shopsimon/shopsimon';
import WearHumans from './wear-humans/wear-humans';
import TravelPro from './travelPro/travel-pro';
import FluentCommerce from './fluent-commerce/fluent-commerce';
import HatchCollections from './hatchcollections/hatchcollections';
// import changeFavicon from './changefavicon';
import PageNotFound from './page-not-found/pageNotFound'
import { Navigate, Route, Routes } from 'react-router-dom';

const Routing = ()=> {
  // const [retailerData, setRetailerData] = useState({});
  // const location = useLocation();

  // useEffect(() => {
    // if (location.pathname.includes('janieandjack')) {
    //   changeFavicon('/ups.svg');
    // } else if (location.pathname.includes('fluentcommerce')) {
    //   changeFavicon('/ups.svg');
    // } else if (location.pathname.includes('shopsimon')) {
    //   changeFavicon('/ups.svg');
    // } else if (location.pathname.includes('hatchcollections')) {
    //   changeFavicon('/ups.svg');
    // } else {
    //   changeFavicon('/fenixcommerce.png');
    // }
      // }, []);
     
    return(
        // <Router>
          <Routes>
          {document.location.hostname.startsWith('janieandjack') && <Route exact path="/" element={<Navigate to="/login" replace/>} />}
          {document.location.hostname.startsWith('janieandjack') && <Route path="" element = {<JanieandJack/>}/>}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/" element = {<JanieandJack/>}/>}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/login" element = {<JanieandJack/>}/>}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/my-orders" element={<JanieandJack/>} />}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/my-orders/:ordernumber/:zipcode" element={<JanieandJack/>} />}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/my-orders/:orderid" element={<JanieandJack/>} />}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/my-orders/return" element={<JanieandJack/>} />}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/my-orders/return/:orderid" element={<JanieandJack/>} />}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/my-orders/return/:ordernumber/:zipcode" element={<JanieandJack/>} />}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/my-returns" element={<JanieandJack/>} />}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/my-returns/:ordernumber/:zipcode" element={<JanieandJack/>} />}
          {document.location.hostname.startsWith('janieandjack') && <Route path="/my-returns/:orderid" element={<JanieandJack/>} />}

          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route exact path="/" element={<Navigate to="/login" replace/>} />}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="" element = {<FluentCommerce/>}/>}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/" element = {<FluentCommerce/>}/>}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/login" element = {<FluentCommerce/>}/>}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/my-orders" element={<FluentCommerce/>} />}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/my-orders/:ordernumber/:zipcode" element={<FluentCommerce/>} />}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/my-orders/:orderid" element={<FluentCommerce/>} />}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/my-orders/return" element={<FluentCommerce/>} />}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/my-orders/return/:ordernumber/:zipcode" element={<FluentCommerce/>} />}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/my-orders/return/:orderid" element={<FluentCommerce/>} />}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/my-returns" element={<FluentCommerce/>} />}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/my-returns/:ordernumber/:zipcode" element={<FluentCommerce/>} />}
          {document.location.hostname === 'selfservice.ppe.fluentcommerce.com' && <Route path="/my-returns/:orderid" element={<FluentCommerce/>} />}
          
          {document.location.hostname.startsWith('shopsimon') && <Route exact path="/" element={<Navigate to="/login" replace/>} />}
          {document.location.hostname.startsWith('shopsimon') && <Route path="" element = {<ShopSimon/>}/>}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/" element = {<ShopSimon/>}/>}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/login" element = {<ShopSimon/>}/>}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/my-orders" element={<ShopSimon/>} />}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/my-orders/:ordernumber/:zipcode" element={<ShopSimon/>} />}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/my-orders/:orderid" element={<ShopSimon/>} />}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/my-orders/return" element={<ShopSimon/>} />}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/my-orders/return/:ordernumber/:zipcode" element={<ShopSimon/>} />}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/my-orders/return/:orderid" element={<ShopSimon/>} />}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/my-returns" element={<ShopSimon/>} />}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/my-returns/:ordernumber/:zipcode" element={<ShopSimon/>} />}
          {document.location.hostname.startsWith('shopsimon') && <Route path="/my-returns/:orderid" element={<ShopSimon/>} />}

          {document.location.hostname.startsWith('hatchcollections') && <Route exact path="/" element={<Navigate to="/login" replace/>} />}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="" element = {<HatchCollections/>}/>}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/" element = {<HatchCollections/>}/>}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/login" element = {<HatchCollections/>}/>}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/my-orders" element={<HatchCollections/>} />}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/my-orders/:ordernumber/:zipcode" element={<HatchCollections/>} />}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/my-orders/:orderid" element={<HatchCollections/>} />}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/my-orders/return" element={<HatchCollections/>} />}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/my-orders/return/:ordernumber/:zipcode" element={<HatchCollections/>} />}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/my-orders/return/:orderid" element={<HatchCollections/>} />}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/my-returns" element={<HatchCollections/>} />}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/my-returns/:ordernumber/:zipcode" element={<HatchCollections/>} />}
          {document.location.hostname.startsWith('hatchcollections') && <Route path="/my-returns/:orderid" element={<HatchCollections/>} />}

          {document.location.hostname.startsWith('wearhumans') && <Route exact path="/" element={<Navigate to="/login" replace/>} />}
          {document.location.hostname.startsWith('wearhumans') && <Route path="" element = {<WearHumans/>}/>}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/" element = {<WearHumans/>}/>}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/login" element = {<WearHumans/>}/>}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/my-orders" element={<WearHumans/>} />}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/my-orders/:ordernumber/:zipcode" element={<WearHumans/>} />}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/my-orders/:orderid" element={<WearHumans/>} />}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/my-orders/return" element={<WearHumans/>} />}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/my-orders/return/:ordernumber/:zipcode" element={<WearHumans/>} />}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/my-orders/return/:orderid" element={<WearHumans/>} />}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/my-returns" element={<WearHumans/>} />}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/my-returns/:ordernumber/:zipcode" element={<WearHumans/>} />}
          {document.location.hostname.startsWith('wearhumans') && <Route path="/my-returns/:orderid" element={<WearHumans/>} />}

          {document.location.hostname.startsWith('travelpro') && <Route exact path="/" element={<Navigate to="/login" replace/>} />}
          {document.location.hostname.startsWith('travelpro') && <Route path="" element = {<TravelPro/>}/>}
          {document.location.hostname.startsWith('travelpro') && <Route path="/" element = {<TravelPro/>}/>}
          {document.location.hostname.startsWith('travelpro') && <Route path="/login" element = {<TravelPro/>}/>}
          {document.location.hostname.startsWith('travelpro') && <Route path="/my-orders" element={<TravelPro/>} />}
          {document.location.hostname.startsWith('travelpro') && <Route path="/my-orders/:ordernumber/:zipcode" element={<TravelPro/>} />}
          {document.location.hostname.startsWith('travelpro') && <Route path="/my-orders/:orderid" element={<TravelPro/>} />}
          {document.location.hostname.startsWith('travelpro') && <Route path="/my-orders/return" element={<TravelPro/>} />}
          {document.location.hostname.startsWith('travelpro') && <Route path="/my-orders/return/:orderid" element={<TravelPro/>} />}
          {document.location.hostname.startsWith('travelpro') && <Route path="/my-orders/return/:ordernumber/:zipcode" element={<TravelPro/>} />}
          {document.location.hostname.startsWith('travelpro') && <Route path="/my-returns" element={<TravelPro/>} />}
          {document.location.hostname.startsWith('travelpro') && <Route path="/my-returns/:ordernumber/:zipcode" element={<TravelPro/>} />}
          {document.location.hostname.startsWith('travelpro') && <Route path="/my-returns/:orderid" element={<TravelPro/>} />}

          <Route path="" element={<PageNotFound/>} />
          <Route path="*" element ={<PageNotFound/>}/>
          </Routes>
          // <Outlet />
      // </Router>
    )
  
}

export default Routing;