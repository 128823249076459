import React from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import Routing from './routes';

function App() {
 
  return (
    <div className="hidden-overflow">
    <Router>
      <Routing />
    </Router>
    {/* <Routing /> */}
    </div>
  );
}

export default App;
