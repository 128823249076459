import './pageNotFound.scss'
import { useNavigate } from 'react-router-dom';
import Navbar from "../navbar/navbar";
import Footer from "../footter/footer";
// import { useEffect, useState } from 'react';

const PageNotFound = () => {
  let data1 = (document.location.hostname.startsWith('janieandjack') ?
    {
      type: 'janieandjack',
      fileUrl: 'janieandjack/image.png',
      vendorName: 'Janie and Jack',
      menuName: [{ 'menu': 'Girl', 'hrefLink': 'https://www.janieandjack.com/girls-clothing?lang=en_US' }, { 'menu': 'Boy', 'hrefLink': 'https://www.janieandjack.com/boys-clothing?lang=en_US' }, { 'menu': 'NewBorn', 'hrefLink': 'https://www.janieandjack.com/newborn-clothing?lang=en_US' }, { 'menu': 'Tween', 'hrefLink': 'https://www.janieandjack.com/shop-all-tween?lang=en_US' }, { 'menu': 'Accessories', 'hrefLink': 'https://www.janieandjack.com/accessories?lang=en_US' }, { 'menu': 'Shoes', 'hrefLink': 'https://www.janieandjack.com/shoes/childrens-shoes?lang=en_US' }, { 'menu': 'Disney', 'hrefLink': 'https://www.janieandjack.com/disney/shop-all?lang=en_US' },
      { 'menu': 'Pajamas', 'hrefLink': 'https://www.janieandjack.com/shop-all-pajamas?lang=en_US' }, { 'menu': 'Sale', 'hrefLink': 'https://www.janieandjack.com/shop-all-sale?lang=en_US' }],
      login_img: 'janieandjack/janieandjack.png',
      storeName: 'janieandjack.com',
      tenantId: '4555cbac57524704afa53d3fe49a555b',
      footer_header: [{
        'header_name': 'CUSTOMER SERVICE', 'nested_values': [{ 'value': 'Need Help?', 'hrefLink': 'https://www.janieandjack.com/customer-service.html?lang=en_US' }, { 'value': 'Accessibility Statement', 'hrefLink': 'https://www.janieandjack.com/accessibility-statement.html?lang=en_US' }, { 'value': 'My Account', 'hrefLink': 'https://www.janieandjack.com/sign-in?lang=en_US&rurl=1' }, { 'value': 'Track Your Order', 'hrefLink': 'https://www.janieandjack.com/trackorder?lang=en_US' },
        { 'value': 'FAQs', 'hrefLink': 'https://www.janieandjack.com/faqs.html?lang=en_US' }]
      },
      { 'header_name': 'PROMOTIONS', 'nested_values': [{ 'value': 'Special Offers', 'hrefLink': 'https://www.janieandjack.com/promotions-coupons.html?lang=en_US' }, { 'value': 'Refer a Friend', 'hrefLink': 'https://www.janieandjack.com/share?lang=en_US' }] },
      { 'header_name': 'SHOPPING WITH US', 'nested_values': [{ 'value': 'Store Locator', 'hrefLink': 'https://www.janieandjack.com/stores?lang=en_US&showMap=true&horizontalView=true&isForm=true' }, { 'value': 'Shipping Information', 'hrefLink': 'https://www.janieandjack.com/shipping-information.html?lang=en_US' }, { 'value': 'Returns', 'hrefLink': 'https://www.janieandjack.com/returns-policy.html?lang=en_US' }, { 'value': 'Gift Services', 'hrefLink': 'https://www.janieandjack.com/gift-services.html?lang=en_US' }, { 'value': 'Size Charts', 'hrefLink': 'https://www.janieandjack.com/janieandjack-size-charts.html?lang=en_US' }, { 'value': 'Popular Categories', 'hrefLink': 'https://www.janieandjack.com/buy/vsm' }] },
      { 'header_name': 'ABOUT US', 'nested_values': [{ 'value': 'Who We Are', 'hrefLink': 'https://www.janieandjack.com/about-jj.html?lang=en_US' }, { 'value': 'Careers', 'hrefLink': 'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=648a76ff-b35c-49e8-80ea-6a3f6cf18843&ccId=9200358583537_2&lang=en_US' }] }
      ],
      recommendedProducts: [{ 'image_url': 'janieandjack/janiejack-1.png', 'title': 'Floral Flutter Sleeve', 'price': '$38.32' }, { 'image_url': 'janieandjack/horsesweater.png', 'title': 'Horse Sweater', 'price': '$ 59.00' },
      { 'image_url': 'janieandjack/frenchterryhooded.png', 'title': 'French Terry Hooded SweatShirt', 'price': '$ 49.00' }, { 'image_url': 'janieandjack/stripedslubtee.png', 'title': 'Stripped Slub Tee', 'price': '$ 59.00' }
      ],
      store_url: 'https://www.janieandjack.com/',
      policyUrl: 'https://www.janieandjack.com/returns-policy.html?lang=en_US'
    } :
    (document.location.hostname === 'selfservice.ppe.fluentcommerce.com' ?
      {
        type: 'fluentcommerce',
        fileUrl: 'fluent-commerce/fluentcommerce-logo.png',
        vendorName: 'Fluent Commerce',
        menuName: [{ 'menu': 'Apparel', 'hrefLink': 'https://fluentcommerce.com/' }, { 'menu': 'Footwear', 'hrefLink': 'https://fluentcommerce.com/' }, { 'menu': 'Electronics', 'hrefLink': 'https://fluentcommerce.com/' }, { 'menu': 'Industries', 'hrefLink': 'https://fluentcommerce.com/resources/digital-resources/7-unique-challenges-of-luxury-fulfillment/' }, { 'menu': 'Resources', 'hrefLink': 'https://fluentcommerce.com/resources/digital-resources/order-management-system-selection-43-smart-questions-to-ask-a-vendor/' }, { 'menu': 'Customers', 'hrefLink': 'https://fluentcommerce.com/resources/case-studies/breitling-case-study/' }, { 'menu': 'Partners', 'hrefLink': 'https://fluentcommerce.com/partners/solution/' }, { 'menu': 'About', 'hrefLink': 'https://fluentcommerce.com/about/' },
        ],
        login_img: 'janieandjack/janieandjack.png',
        storeName: 'fluentcommerce-sandbox',
        tenantId: '415d867c8924494195006dbcc877973d',
        footer_header: [{ 'header_name': 'Products', 'nested_values': [{ 'value': 'Fluent Big Inventory', 'hrefLink': 'https://fluentcommerce.com/product/fluent-big-inventory/' }, { 'value': 'Global Inventory', 'hrefLink': 'https://fluentcommerce.com/product/fluent-big-inventory/' }, { 'value': 'Order Management', 'hrefLink': 'https://fluentcommerce.com/product/' }, { 'value': 'Product Availability', 'hrefLink': 'https://fluentcommerce.com/product/fluent-order-promising/' },] },
        { 'header_name': 'Use Cases', 'nested_values': [{ 'value': 'Click & Collect', 'hrefLink': 'https://fluentcommerce.com/solutions/click-collect/' }, { 'value': 'Customer Service', 'hrefLink': 'https://fluentcommerce.com/solutions/customer-service/' }, { 'value': 'In-Store Pick and Pack', 'hrefLink': 'https://fluentcommerce.com/solutions/in-store-pick-and-pack/' }, { 'value': 'Headless Commerce', 'hrefLink': 'https://fluentcommerce.com/solutions/headless-commerce/' }, { 'value': 'Fulfillment Optimization', 'hrefLink': 'https://fluentcommerce.com/solutions/fulfillment-optimization/' },] },
        { 'header_name': 'Resources', 'nested_values': [{ 'value': 'Blog', 'hrefLink': 'https://fluentcommerce.com/resources/blog/' }, { 'value': 'Case Study', 'hrefLink': 'https://fluentcommerce.com/resources/blog/resources/by-type/case-studies/' }, { 'value': 'eBook', 'hrefLink': 'https://fluentcommerce.com/resources/blog/resources/by-type/solution-briefs-ebooks/' }, { 'value': 'Contact US', 'hrefLink': 'https://fluentcommerce.com/contact-us/' }] },
        // {'header_name':'Company','nested_values':[{'value':'About','hrefLink':'https://fluentcommerce.com/about/'},{'value':'Customers','hrefLink':'https://fluentcommerce.com/customers/brands/'},{'value':'Successful projects','hrefLink':'https://fluentcommerce.com/customers/go-lives/'},{'value':'Partners','hrefLink':'https://fluentcommerce.com/partners/'},{'value':'Careers','hrefLink':'https://careers.fluentcommerce.com/'}]},
        { 'header_name': 'Stay Connected', 'nested_values': [{ 'value': 'LinkedIn', 'hrefLink': 'https://www.linkedin.com/company/fluent-commerce/' }, { 'value': 'Youtube', 'hrefLink': 'https://www.youtube.com/channel/UCl1XKol5goYSAjkEmnCPz3g' }] }
        ],
        recommendedProducts: [{ 'image_url': 'janieandjack/janiejack-1.png', 'title': 'Floral Flutter Sleeve', 'price': '$38.32' }, { 'image_url': 'janieandjack/horsesweater.png', 'title': 'Horse Sweater', 'price': '$ 59.00' },
        { 'image_url': 'janieandjack/frenchterryhooded.png', 'title': 'French Terry Hooded SweatShirt', 'price': '$ 49.00' }, { 'image_url': 'janieandjack/stripedslubtee.png', 'title': 'Stripped Slub Tee', 'price': '$ 59.00' }
        ],
        store_url: 'https://fluentcommerce.com/',
        policyUrl: 'https://fluentcommerce.com/'

      } :
      (document.location.hostname.startsWith('shopsimon') ?
        {
          type: 'shopsimon',
          vendorName: 'Shop Simon',
          fileUrl: 'shopsimon/ss-logo.svg',
          menuName: [
            // {'menu': 'Deals', 'hrefLink': 'https://shop.simon.com/collections/curated-deals' }, {'menu': 'Just In', 'hrefLink': 'https://shop.simon.com/collections/curated-just-in' }, {'menu': 'Brands', 'hrefLink': 'https://shop.simon.com/pages/brands' },{ 'menu': 'Designers', 'hrefLink': 'https://shop.simon.com/pages/luxe-designers' },
            { 'menu': 'Women', 'hrefLink': 'https://shop.simon.com/collections/women' }, { 'menu': 'Men', 'hrefLink': 'https://shop.simon.com/collections/men' }, { 'menu': 'Shoes', 'hrefLink': 'https://shop.simon.com/collections/shoes' }, { 'menu': 'Handbags', 'hrefLink': 'https://shop.simon.com/collections/women-handbags' },
            { 'menu': 'Kids', 'hrefLink': 'https://shop.simon.com/collections/kids' }, { 'menu': 'Home', 'hrefLink': 'https://shop.simon.com/pages/home' }, { 'menu': 'Beauty', 'hrefLink': 'https://shop.simon.com/collections/accessories-health-beauty' }, { 'menu': 'Preloved', 'hrefLink': 'https://shop.simon.com/collections/curated-preloved' }],
          login_img: 'shopsimon/shopsimon.jpg',
          storeName: 'shoppremiumoutlets.myshopify.com',
          tenantId: '4555cbac57524704afa53d3fe49a497d',
          recommendedProducts: [{ 'image_url': 'shopsimon/bag-1.png', 'title': 'Simplie Light weight Expandable', 'price': '$138.54' }, { 'image_url': 'shopsimon/redshirt.png', 'title': 'Eco Campania Tee', 'price': '$ 25.59' },
          { 'image_url': 'shopsimon/furniture-4.png', 'title': 'Loungie Cloudy Bean Bag', 'price': '$ 122.00' }, { 'image_url': 'shopsimon/cloth-3.png', 'title': 'Gaphic Series Vector Tank Top ', 'price': '$ 7.49' }
          ],
          footer_header: [{
            'header_name': 'Help', 'nested_values': [{ 'value': 'Help/FAQs?', 'hrefLink': 'https://support.shopsimon.com/hc/en-us' }, { 'value': 'Contact Us', 'hrefLink': 'https://shop.simon.com/pages/contact-us' }, { 'value': 'Shipping Policy', 'hrefLink': 'https://support.shopsimon.com/hc/en-us/articles/360051095694-Shipping-Policy' }, { 'value': 'Return Policy', 'hrefLink': 'https://support.shopsimon.com/hc/en-us/articles/360051106334-Return-Policy' },
            { 'value': 'Refund Policy', 'hrefLink': 'https://shop.simon.com/policies/refund-policy' }]
          },
          { 'header_name': 'About', 'nested_values': [{ 'value': 'About Us', 'hrefLink': 'https://shop.simon.com/pages/about-shop-simon' }, { 'value': 'Rewards', 'hrefLink': 'https://shop.simon.com/pages/rewards' }, { 'value': 'Influencer Program', 'hrefLink': 'https://shop.simon.com/pages/influencer' }, { 'value': 'Get the App', 'hrefLink': 'https://shop.simon.com/pages/app' }, { 'value': 'Livestreams', 'hrefLink': 'https://shop.simon.com/pages/live' }] },
          { 'header_name': 'Social Media', 'nested_values': [{ 'value': 'Instagram', 'hrefLink': 'https://www.instagram.com/shopsimon/' }, { 'value': 'Facebook', 'hrefLink': 'https://www.facebook.com/shopsimon.us' }, { 'value': 'Pinterest', 'hrefLink': 'https://www.pinterest.com/shopsimon/' }, { 'value': 'Tiktok', 'hrefLink': 'https://www.tiktok.com/@shopsimon' }] },
          ],
          store_url: 'https://shop.simon.com/',
          policyUrl: 'https://support.shopsimon.com/hc/en-us/articles/360051106334-Return-Policy'

        } :(document.location.hostname.startsWith('hatchcollections') ?
        {
          type: 'hatchcollections',
          fileUrl: 'hatchcollections/hatchcollection-logo.png',
          vendorName: 'Hatch Collections',
          menuName: [{ 'menu': 'NEW ARRIVALS', 'hrefLink': 'https://www.hatchcollection.com/collections/new-arrivals' }, { 'menu': 'OUR TOP 20', 'hrefLink': 'https://www.hatchcollection.com/collections/top-20-capsule' }, { 'menu': 'MATERNITY CLOTHES', 'hrefLink': 'https://www.hatchcollection.com/collections/view-all' }, { 'menu': 'AFTER BABY', 'hrefLink': 'https://www.hatchcollection.com/collections/shop-all-fourth-trimester' },
          { 'menu': 'BEAUTY + WELLNESS', 'hrefLink': 'https://www.hatchcollection.com/collections/beauty-collection' }, { 'menu': 'SALE ON SALE', 'hrefLink': 'https://www.hatchcollection.com/collections/sale' }],
          login_img: 'hatchcollections/hatch-collection-login.png',
          storeName: 'hatchcollection.myshopify.com',
          tenantId: 'dbf30db1e5ac42d7a86ec70bc0f2baec',
          recommendedProducts: [{ 'image_url': 'shopsimon/bag-1.png', 'title': 'Simplie Light weight Expandable', 'price': '$138.54' }, { 'image_url': 'shopsimon/redshirt.png', 'title': 'Eco Campania Tee', 'price': '$ 25.59' },
          { 'image_url': 'shopsimon/furniture-4.png', 'title': 'Loungie Cloudy Bean Bag', 'price': '$ 122.00' }, { 'image_url': 'shopsimon/cloth-3.png', 'title': 'Gaphic Series Vector Tank Top ', 'price': '$ 7.49' }
          ],
          footer_header: [{ 'header_name': 'Company', 'nested_values': [{ 'value': 'About', 'hrefLink': 'https://www.hatchcollection.com/pages/about' }, { 'value': 'Carees', 'hrefLink': 'https://www.hatchcollection.com/pages/careers' }, { 'value': 'Stores', 'hrefLink': 'https://www.hatchcollection.com/pages/stores' }, { 'value': 'Gift Cards', 'hrefLink': 'https://www.hatchcollection.com/products/hatch-gift-card' }] },
          { 'header_name': 'Community', 'nested_values': [{ 'value': 'Babe', 'hrefLink': 'https://babe.hatchcollection.com/' }, { 'value': 'Events', 'hrefLink': 'https://events.hatchcollection.com/communityevents' }, { 'value': 'Store Services', 'hrefLink': 'https://www.hatchcollection.com/pages/shop-home' }, { 'value': 'Shop @ Home', 'hrefLink': 'https://www.hatchcollection.com/pages/store-services' }, { 'value': 'Registry Quiz', 'hrefLink': 'https://www.hatchcollection.com/pages/nikkis-list' }] },
          { 'header_name': 'Help', 'nested_values': [{ 'value': 'FAQ', 'hrefLink': 'https://www.hatchcollection.com/pages/faq' }, { 'value': 'Returns & Exchanges', 'hrefLink': 'https://returnportal.hatchcollection.com/' }, { 'value': 'Track Your Order', 'hrefLink': 'https://www.hatchcollection.com/account/login' }, { 'value': 'Contact Us', 'hrefLink': 'https://www.hatchcollection.com/pages/contact-us-form' }, { 'value': 'Buyback Program', 'hrefLink': 'https://www.hatchcollection.com/pages/croissant-landing-page' }] },
          { 'header_name': 'Follow Us', 'nested_values': [{ 'value': 'Facebook', 'hrefLink': 'https://www.facebook.com/HATCHcollection' }, { 'value': 'Instagram', 'hrefLink': 'https://www.instagram.com/hatchgal/' }, { 'value': 'Pinterest', 'hrefLink': 'https://www.pinterest.com/hatchcollection/' }, { 'value': 'Twitter', 'hrefLink': 'https://x.com/hatchcollection?lang=en' }] }
          ],
          store_url: 'https://www.hatchcollection.com/',
          policyUrl: 'https://www.hatchcollection.com/pages/privacy-policy'

        }:( (document.location.hostname.startsWith('travelpro') ? 
          {
            type:'travelpro',
            fileUrl:'travelpro/travelpro.png',
            vendorName:'Travelpro',
            menuName:[{'menu':'Luggage','hrefLink':'https://travelpro.com/pages/shop-luggage-by-type'},{'menu':'Bags','hrefLink':'https://travelpro.com/collections/travelpro-bags'},{'menu':'Accessories','hrefLink':'https://travelpro.com/collections/travelpro-essentials'},{'menu':'Collections','hrefLink':'https://travelpro.com/pages/view-by-collection'},{'menu':'Customer Service','hrefLink':'https://travelpro.com/pages/customer-service-center'},{'menu':'About us','hrefLink':'https://travelpro.com/pages/about-travelpro'},{'menu':'Aviation Professionals','hrefLink':'https://aviation.travelpro.com/'}],
            login_img:'travelpro/travelpro-login.png',
            storeName:'mytravelpro.myshopify.com',
            tenantId:'613abc6af6ea4c488d9e38cbb159453d',
            recommendedProducts:[{'image_url':'travelpro/medium-luggage.png','title':'Maxlite® Air Medium','price':'$186.99'},{'image_url':'travelpro/medium-luggage-01.png','title':'Platinum® Elite Medium','price':'$ 343.99'},
                {'image_url':'travelpro/maxlite-dropbottom.png','title':'Maxlit Drop-Bottom','price':'$ 101.99'},{'image_url':'travelpro/medium-topload-03.png','title':'Maxlite® Laptop Backpack','price':'$ 101.32'}
            ],
            footer_header:[{'header_name':'SHOP','nested_values':[{'value':'Carry On Luggage','hrefLink':'https://travelpro.com/collections/carry-on-luggage'},{'value':'Checked Luggage','hrefLink':'https://travelpro.com/collections/checked-luggage'},{'value':'Hard Shell Luggage ','hrefLink':'https://travelpro.com/collections/hard-side-luggage'},{'value':'Garment Bags','hrefLink':'https://travelpro.com/collections/garment-bags'}]},
                {'header_name':'CUSTOMER SERVICE','nested_values':[{'value':'Customer Service Center','hrefLink':'https://travelpro.com/pages/customer-service-center'},{'value':'FAQs','hrefLink':'https://travelpro.com/pages/travelpro-f-a-q-frequently-asked-questions'},{'value':'Orders and Shipping','hrefLink':'https://travelpro.com/pages/travelpro-f-a-q-frequently-asked-questions#orders'},{'value':'Returns & Refunds', 'hrefLink':'https://travelpro.com/pages/travelpro-f-a-q-frequently-asked-questions#Returns'},{'value':'Contact Customer Service','hrefLink':'https://travelpro.com/pages/contact-travelpro-products'}]},
                {'header_name':'DISCOVER','nested_values':[{'value':'About Travel Pro','hrefLink':'https://travelpro.com/pages/about-travelpro'},{'value':'Our History','hrefLink':'https://travelpro.com/pages/our-history'},{'value':'TravelPRO Guides','hrefLink':'https://travelpro.com/pages/travelpro-guides-hub'},{'value':'Blog', 'hrefLink':'https://travelpro.com/blogs/the-travelpro-blog'}]},
                {'header_name':'STAY CONNECTED','nested_values':[{'value':'Facebook','hrefLink':'https://www.facebook.com/travelproproducts/'},{'value':'Instagram','hrefLink':'https://www.instagram.com/travelprointl/'},{'value':'Twitter','hrefLink':'https://x.com/travelprointl'},{'value':'Youtube','hrefLink':'https://www.youtube.com/user/TravelproLuggage'}]},
            ],
            store_url:'https://travelpro.com/'
    
        } : ''
        ))))));
  let navigate = useNavigate();
  const gobacktologin = () => {
    let data2 = JSON.stringify(data1.type);
    data2 = '/login'
    navigate(data2.replace(/"/g, ""));
  }

  return (
    <div>
      <Navbar data={data1}></Navbar>
      <div className="not-found-container ">
        <h1 className={`${data1?.type} fw-600`}>OOPS!</h1>
        <p className={`${data1?.type} error-message`}>The page you requested was not found.</p>
        <p className={`${data1?.type} error-description`}>
          Sorry! The page you were looking for could not be found.<br />
          This page may have been moved or deleted.
        </p>
        {/* <a href="/janieandjack" className="home-button">Go to Login Page</a>  */}
        <button className={`${data1?.type ? data1.type + '-btn' : ''} ${data1?.type === 'fluentcommerce' ? 'full-width' : ''} mb-16 float-right`} onClick={gobacktologin} >
          Go to Login Page </button>
      </div>
      <Footer data={data1}></Footer>
    </div>
  )
}

export default PageNotFound;