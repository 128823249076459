import React, { useEffect } from "react";
import PropTypes from 'prop-types';
// import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

function SimpleDialog(props) {
  const [data1, setRetailerData] = React.useState({});
  const { onClose, open } = props;
  const handleClose = ( isChecked ) => {
      onClose(isChecked)
    };

useEffect(()=>{
    setRetailerData(JSON.parse(sessionStorage.getItem('retailerData')));
},[])

  return (
    <div>
    <Dialog open={open}>
      <DialogTitle>Are you sure ? </DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            It will discard your previous selection.
          </DialogContentText>
        </DialogContent>
      <DialogActions className="justify-content-center">
      <button className={` ${data1?.type ? data1.type+'-btn':''}`} onClick={() => handleClose(false)}>Cancel</button>
      <button className={`${data1?.type} tertiary-btn`}  onClick={() => handleClose(true)}>Proceed</button>
    </DialogActions>
    </Dialog>
    </div>
  );
}
SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SimpleDialog;