import './navbar.scss';
import './../styles/index.scss';
import { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { RETURNS_UI_URLS } from '../constants';
// import LogoutIcon from '@mui/icons-material/Logout';

export default function Navbar(data1) {
  let data = data1.data;
  const [note, setNote] = useState({});
  
  useEffect(() => {
    getNoteText();
  },[])

    const getNoteText = async () => {   
        try{     
            const response = await fetch(RETURNS_UI_URLS.ADMIN_BASE_URL+'header-banner-content', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId)
                }
            });
            if (response?.status === 200) {
                const result = await response.json();
                    if (result) {
                        setNote(result);
                    }
                    else {
                        setNote(null);
                    }
            }
            else {
                setNote(null);
            }
        }
    catch (error) {
        setNote(null);
    }

    }

    const clearNote = () => {        
        setNote(note.display = false);
    }

    return (
        <div className={`nav-wrapper ${data?.type}wrap`}>
           {note?.display ?
            <div className={` display-flex ${data?.type}note-wrapper text-center f-16 p-10 fw-500 `} >
             <div className="width-98per" >
            <div className='' dangerouslySetInnerHTML={{ __html: note?.content }} ></div>
            </div>
             <div className="align-center">
             <ClearIcon className='f-20 white-color cursor-pointer' onClick={() => clearNote()} />
            </div> 
            </div>
             :''}
            <div className="row d-main-logo">
                <div className="w">
                <div className="col-md-12 text-center p-10">
                            <img className='cursor-pointer logo-size' src={require(`../assets/images/${data?.fileUrl}`)} onClick={() => window.open(data?.store_url)} alt={data?.type} />
                            {/* {<span className='float-right mt-10'><LogoutIcon sx={{ fontSize: 24, color:'#1b375d' }}/>&nbsp;&nbsp;<a href='login' onClick={e => window.history.go(-1)} className="primary-color fw-500">Logout</a></span>} */}
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-md">
                <button className="navbar-toggler ham-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon ham-span">
                        <i className="fa fa-navicon f-icon"></i>
                    </span>
                </button>
                    <img src={require(`../assets/images/${data?.fileUrl}`)} className={`mobile-logo cursor-pointer ${data?.type}`} onClick={() => window.open(data?.store_url)} alt={data?.type} />
                <div className={`collapse navbar-collapse justify-content-center navbar-default mob-nav ${data?.type}`} id="navbarNav">
                    <ul className='navbar-nav'>
                         {data?.menuName?.map((menu,key) => {
                        return <li className="nav-item"  key={key}>
                            <a className={`header-menu nav-link ${data?.type === 'janieandjack' || data?.type === 'hatchcollections' || data?.type === 'wearhumans' ? data?.type :'white-color'} cursor-pointer`} href={menu.hrefLink}
                                target="_blank" rel="noopener noreferrer">{menu.menu}</a>
                        </li>
                        })}
                        {/* <li className='float-right'>
                        {<span className='float-right mt-10'><LogoutIcon sx={{ fontSize: 24, color:'#1b375d' }}/>&nbsp;&nbsp;<a href='login' onClick={e => window.history.go(-1)} className="primary-color fw-500">Logout</a></span>}
                        </li> */}
                    </ul>
                </div>
            </nav>
        </div>
    )
}
