import { useEffect,useState } from "react";
import Footer from "../footter/footer";
import Navbar from "../navbar/navbar";
import Fenixorder from "../return portal/fenix orders/fenix-order";
import { useNavigate } from "react-router-dom";
import Loginportal from "../return portal/return-portal";



function WearHumans(){
    const [showDelayedFile, setShowDelayedFile] = useState(false);
    const navigate = useNavigate();    

    let obj ={
        type:'wearhumans',
        fileUrl:'wearhumans/wearhumans.svg',
        vendorName:'Wearhumans',
        menuName:[{'menu':'Women','hrefLink':'https://wearhumans.com/collections/all/women'},{'menu':'Men','hrefLink':'https://wearhumans.com/collections/all/men'},{'menu':'Kids','hrefLink':'https://wearhumans.com/'},
        {'menu':'New Arrivals','hrefLink':'https://wearhumans.com/'},{'menu':'Offers','hrefLink':'https://wearhumans.com/'}],
        login_img:'wearhumans/wearhumanslogin.svg',
        storeName:'wearhumans.myshopify.com',
        tenantId:'5075a60d701746ca80de05c8bd4b253d',
        // tenantId:'4555cbac57524704afa53d3fe49a555b',
        footer_header:[{'header_name':'SUPPORT','nested_values':[{'value':'FAQ','hrefLink':'https://wearhumans.com/'},{'value':'Contact Us','hrefLink':'https://wearhumans.com/'},
                {'value':'My Account','hrefLink':'https://wearhumans.com/'},{'value':'Product Registration','hrefLink':'https://wearhumans.com/'},{'value':'Track My Order','hrefLink':'https://wearhumans.com/'},{'value':'Returns & Exchanges','hrefLink':'https://wearhumans.com/'}]},
                {'header_name':'COMPANY','nested_values':[{'value':'Careers','hrefLink':'https://wearhumans.com/'},{'value':'Warranty','hrefLink':'https://wearhumans.com/'},{'value':'Privacy Policy','hrefLink':'https://wearhumans.com/'},{'value':'Repair & Sustainability','hrefLink':'https://wearhumans.com/'}]},
                {'header_name':'ABOUT','nested_values':[{'value':'Blog','hrefLink':'https://wearhumans.com/'},{'value':'Ambassador & Affiliates','hrefLink':'https://wearhumans.com/'},{'value':'Military & First Response','hrefLink':'https://wearhumans.com/'},{'value':'Insider Rewards','hrefLink':'https://wearhumans.com/'},{'value':'Give $30 and Get $30','hrefLink':'https://wearhumans.com/'}]},
                {'header_name':'SALES','nested_values':[{'value':'Store Locator','hrefLink':'https://wearhumans.com/'},{'value':'Corporate Gifting','hrefLink':'https://wearhumans.com/'},{'value':'Wholesale Portal','hrefLink':'https://wearhumans.com/'}]}],
        recommendedProducts:[{'image_url':'wearhumans/whiteshirt.svg','title':'white T-Shirt','price':'$13.00'},{'image_url':'wearhumans/darkgreen.svg','title':'Dark Green Sweatshirt','price':'$ 127.99'},
                {'image_url':'wearhumans/lavendershirt.svg','title':'Levender Sweatshirt','price':'$ 133.00'},{'image_url':'wearhumans/darkgreen.svg','title':'Dark Green Sweatshirt','price':'$ 127.99'}],
        store_url:'https://www.wearhumans.com/',
        policyUrl:'https://www.wearhumans.com/returns-policy.html?lang=en_US'
    }
    sessionStorage.setItem('retailerData', JSON.stringify(obj));
    useEffect(() => {
        setTimeout(() => {
            setShowDelayedFile(true);
        }, 1000); 
        let tmp = document.location.href.slice(document.location.href?.lastIndexOf("/") , document.location.href?.length) ;        
        if(tmp === '/'  || document.location.href.includes('login') || (!document.location.search && !sessionStorage.getItem('email'))) {
            let data2 = ''; 
            sessionStorage.removeItem('returnItemData');
            sessionStorage.removeItem('selectedReasonData');
            sessionStorage.removeItem('selectedDummyReasonData');
            sessionStorage.removeItem('isSelectedLineItems');
            sessionStorage.removeItem('RPI');
            sessionStorage.removeItem('email'); 
            sessionStorage.removeItem('tabIndex');
            sessionStorage.removeItem('orderInfo');
            sessionStorage.removeItem('retailername');
            data2 = '/login';
            navigate(data2.replace(/"/g, "")); 
        }      
       },[navigate,obj.type]);
    
    return (
        <div className="hidden-overflow">
            <Navbar data={obj}></Navbar>
            {(document.location.search || sessionStorage.getItem('email')) && !(document.location.href.includes('login')) ? <Fenixorder data={JSON.parse(JSON.stringify(obj))}></Fenixorder> : <Loginportal data={JSON.parse(JSON.stringify(obj))}></Loginportal>}
            {/* <BestSellers data={obj}></BestSellers> */}
            {showDelayedFile && (
            <Footer data={obj}></Footer>
            )}
        </div>
      );;
}

export default WearHumans