export const RETURNS_UI_URLS ={
    BASE_URL:'https://srv-02.returns.fenixcommerce.com/fenixreturns/2024-08/',
    // BASE_URL:'https://dev-01.returns.fenixcommerce.com/fenixreturns/2024-08/',
    ADMIN_BASE_URL:'https://srv-02.returns.fenixcommerce.com/fenixreturns/admin/2024-08/',
    // ADMIN_BASE_URL:'https://dev-01.returns.fenixcommerce.com/fenixreturns/admin/2024-08/',
}

export const CUSTOMER_LOGIN = {
    LOG_IN_URL: RETURNS_UI_URLS.BASE_URL+'_clogin',  
    VALIDATE_OTP:RETURNS_UI_URLS.BASE_URL +'login/validate_otp'
}

export const ORDER_HISTORY_SERACH = {
    ORDER_HISTORY : RETURNS_UI_URLS.BASE_URL + 'order-history/email/search?page=' ,
    ORDER_NUMBER_AND_EMAIL : RETURNS_UI_URLS.BASE_URL + 'customer-portal/order-history/search?page='    
}

export const RETURN_REASON_SUMMARY = {
    RETURN_REASON:RETURNS_UI_URLS.BASE_URL+ 'return-policy/'
}

export const RETURNS_SEARCH ={
    Return_Search :RETURNS_UI_URLS.BASE_URL + 'customer-portal/return-summary/search?page='
}

export const PRODUCT_RECOMMENDED ={
    RECOMMEDNDED_PRODUCTS: RETURNS_UI_URLS.BASE_URL + 'recommended-product/recommended_products?order_id=5849417973932'
}
 
export const FILE_UPLOAD ={ 
    FILE_UPLOAD_URL : RETURNS_UI_URLS.BASE_URL + 'file-upload',
}
