import { useEffect,useState } from "react";
import Footer from "../footter/footer";
import Navbar from "../navbar/navbar";
// import ReturnItem from "../return portal/fenix orders/retrun item/return-item";
// import ReturnedOrders from "../return portal/fenix orders/returned-orders/returned-orders";
import Loginportal from "../return portal/return-portal";
import Fenixorder from "../return portal/fenix orders/fenix-order";
import { useNavigate } from "react-router-dom";
// import { Twitter } from "@mui/icons-material";
// import BestSellers from "../best-sellers/best-sellers";
// import MyOrders from "../return portal/fenix orders/my-orders/my-orders";



function HatchCollections(){
    const [showDelayedFile, setShowDelayedFile] = useState(false);
    const navigate = useNavigate();    

    let obj ={
        type:'hatchcollections',
        fileUrl:'hatchcollections/hatchcollection-logo.png',
        vendorName:'Hatch Collections',
        menuName:[{'menu':'NEW ARRIVALS','hrefLink':'https://www.hatchcollection.com/collections/new-arrivals'},{'menu':'OUR TOP 20','hrefLink':'https://www.hatchcollection.com/collections/top-20-capsule'},{'menu':'MATERNITY CLOTHES','hrefLink':'https://www.hatchcollection.com/collections/view-all'},{'menu':'AFTER BABY','hrefLink':'https://www.hatchcollection.com/collections/shop-all-fourth-trimester'},
        {'menu':'BEAUTY + WELLNESS','hrefLink':'https://www.hatchcollection.com/collections/beauty-collection'},{'menu':'SALE ON SALE','hrefLink':'https://www.hatchcollection.com/collections/sale'}],
        login_img:'hatchcollections/hatch-collection-login.png',
        storeName:'hatchcollection.myshopify.com',
        tenantId:'dbf30db1e5ac42d7a86ec70bc0f2baec',
        recommendedProducts:[{'image_url':'shopsimon/bag-1.png','title':'Simplie Light weight Expandable','price':'$138.54'},{'image_url':'shopsimon/redshirt.png','title':'Eco Campania Tee','price':'$ 25.59'},
            {'image_url':'shopsimon/furniture-4.png','title':'Loungie Cloudy Bean Bag','price':'$ 122.00'},{'image_url':'shopsimon/cloth-3.png','title':'Gaphic Series Vector Tank Top ','price':'$ 7.49'}
        ],
        footer_header:[{'header_name':'Company','nested_values':[{'value':'About','hrefLink':'https://www.hatchcollection.com/pages/about'},{'value':'Carees','hrefLink':'https://www.hatchcollection.com/pages/careers'},{'value':'Stores','hrefLink':'https://www.hatchcollection.com/pages/stores'},{'value':'Gift Cards','hrefLink':'https://www.hatchcollection.com/products/hatch-gift-card'}]},
            {'header_name':'Community','nested_values':[{'value':'Babe','hrefLink':'https://babe.hatchcollection.com/'},{'value':'Events','hrefLink':'https://events.hatchcollection.com/communityevents'},{'value':'Store Services','hrefLink':'https://www.hatchcollection.com/pages/shop-home'},{'value':'Shop @ Home', 'hrefLink':'https://www.hatchcollection.com/pages/store-services'},{'value':'Registry Quiz','hrefLink':'https://www.hatchcollection.com/pages/nikkis-list'}]},
            {'header_name':'Help','nested_values':[{'value':'FAQ','hrefLink':'https://www.hatchcollection.com/pages/faq'},{'value':'Returns & Exchanges','hrefLink':'https://returnportal.hatchcollection.com/'},{'value':'Track Your Order','hrefLink':'https://www.hatchcollection.com/account/login'},{'value':'Contact Us','hrefLink':'https://www.hatchcollection.com/pages/contact-us-form'},{'value':'Buyback Program','hrefLink':'https://www.hatchcollection.com/pages/croissant-landing-page'}]},
            {'header_name':'Follow Us','nested_values':[{'value':'Facebook','hrefLink':'https://www.facebook.com/HATCHcollection'},{'value':'Instagram','hrefLink':'https://www.instagram.com/hatchgal/'},{'value':'Pinterest','hrefLink':'https://www.pinterest.com/hatchcollection/'},{'value':'Twitter','hrefLink':'https://x.com/hatchcollection?lang=en'}]}
        ],
        store_url:'https://www.hatchcollection.com/',
        policyUrl:'https://www.hatchcollection.com/pages/privacy-policy'

    }
    sessionStorage.setItem('retailerData', JSON.stringify(obj));
    
    useEffect(() => {
        setTimeout(() => {
            setShowDelayedFile(true)
        }, 1000); 
        let tmp = document.location.href.slice(document.location.href?.lastIndexOf("/") , document.location.href?.length) ;        
        if(tmp === '/'  || document.location.href.includes('login') || (!document.location.search && !sessionStorage.getItem('email'))) {
            let data2 = ''; 
            sessionStorage.removeItem('returnItemData');
            sessionStorage.removeItem('selectedReasonData');
            sessionStorage.removeItem('selectedDummyReasonData');
            sessionStorage.removeItem('isSelectedLineItems');
            sessionStorage.removeItem('RPI');
            sessionStorage.removeItem('email'); 
            sessionStorage.removeItem('tabIndex');
            sessionStorage.removeItem('orderInfo');
            sessionStorage.removeItem('retailername');
            data2 = '/login';
            navigate(data2.replace(/"/g, "")); 
        }        
       },[navigate,obj.type]);
    
    return (
        <div className="hidden-overflow">
            <Navbar data={obj}></Navbar>
            {(document.location.search || sessionStorage.getItem('email')) && !(document.location.href.includes('login')) ? <Fenixorder data={JSON.parse(JSON.stringify(obj))}></Fenixorder> : <Loginportal data={JSON.parse(JSON.stringify(obj))}></Loginportal>}
            {/* <BestSellers data={obj}></BestSellers> */}
            {showDelayedFile && (
            <Footer data={obj}></Footer>
            )}
        </div>
      );
}

export default HatchCollections;