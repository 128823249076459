import './footer.scss';
import './../styles/index.scss';
import Fenixlogo from "./../assets/images/fenix_logo.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import { useState } from 'react';


const Footer = (data1) => {
    let data = data1.data;
    const socialMediaIcons = {
        LinkedIn: <LinkedInIcon className={`${data.type} f-35`} />,
        Twitter: <XIcon className={`${data.type} f-30`} />,
        Youtube: <YouTubeIcon className={`${data.type} f-35`} />,
        Pinterest: <PinterestIcon className={`${data.type} f-35`} />,
        Instagram: <InstagramIcon className={`${data.type} f-35`} />,
        Facebook: <FacebookIcon className={`${data.type} f-35`} />,
        Tiktok: (<svg className={`${data.type} f-35`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.05 25.05" width="30" height="30">
            <g id="a"></g><g id="b"><g id="c">
                <path className="d" style={{ fill: `${data.type}` }}
                    d="M21.38,3.67C16.49-1.22,8.56-1.22,3.67,3.67-1.22,8.56-1.22,16.49,3.67,21.38c4.89,4.89,12.82,4.89,17.71,0,4.89-4.89,4.89-12.82,
                    0-17.71Zm-6.11,6.5v4.77c.03,2.16-1.18,4.28-3.43,4.71-.63,.12-1.2,.13-2.15-.08-5.49-1.64-3.67-9.79,1.85-8.92,0,2.63,0,0,0,2.63-2.28-.34-3.04,
                    1.56-2.44,2.92,.55,1.24,2.82,1.5,3.61-.24,.09-.34,.13-.73,.13-1.17V5.32h2.43c0,.23,.06,3.4,3.39,3.6,0,2.95,0,0,0,2.45-.25,.01-2.19-.13-3.4-1.2Z"
                ></path></g></g></svg>)
    }
    const isSocialMedia = (headerName) => {
        return (
            headerName === 'Social Media' ||
            headerName === 'Stay Connected' ||
            headerName === 'Follow Us'
        );
    };

    return (
        <div className={`footer mt-35 mm-t40 ${data.type} `}>
            <div className="newfooter container pt-16">
                {data.type === 'janieandjack' && (<div>
                    <a className="texthrlink ct-footer-list-header m-10" href="https://www.facebook.com/janieandjack" target="_blank" rel="noopener noreferrer">
                        <FacebookIcon className='f-35' />
                        {/* <svg width="28" height="28" viewBox="0 0 24 24">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M13.621 21V13.125H16.3447L16.8632 9.8674H13.621V7.75346C13.621 6.86227 14.0739 5.99356 15.526 5.99356H17V3.22007C17 3.22007 15.6624 3 14.3835 3C11.7134 3 9.96807 4.56023 9.96807 7.38467V9.8674H7V13.125H9.96807V21H13.621Z"
                                    fill="#63666A"></path>
                            </svg> */}
                    </a>
                    <a className="texthrlink ct-footer-list-header " href="https://www.instagram.com/janieandjack/" target="_blank" rel="noopener noreferrer">
                        <InstagramIcon className='f-35' />
                        {/* <svg width="28" height="28" viewBox="0 0 24 24">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M15.7122 20.9334C17.1539 20.8651 18.4309 20.5358 19.4831 19.4797C20.5352 18.4275 20.8645 17.1505 20.9367 15.7089C21.0211 14.223 21.0211 9.77358 20.9367 8.28775C20.8685 6.84608 20.5392 5.56908 19.4831 4.51694C18.4309 3.46484 17.1539 3.13554 15.7122 3.06327C14.2264 2.97891 9.77292 2.97891 8.28709 3.06327C6.84946 3.1315 5.57245 3.4608 4.51632 4.51294C3.46018 5.56508 3.13488 6.84208 3.0626 8.28375C3.02466 8.95238 3.00379 10.2219 3 11.5516V12.441C3.00379 13.7707 3.02466 15.0402 3.0626 15.7089C3.13088 17.1505 3.46018 18.4275 4.51632 19.4797C5.57245 20.5318 6.84546 20.8611 8.28709 20.9334C9.77292 21.0177 14.2264 21.0177 15.7122 20.9334ZM12.0017 19.3752C10.6925 19.3752 7.87752 19.4797 6.69687 19.0138C5.90979 18.7006 5.30339 18.0942 4.98617 17.3031C4.51632 16.1185 4.62475 13.3075 4.62475 11.9983C4.62475 10.6892 4.52032 7.87414 4.98617 6.69349C5.29939 5.90642 5.90575 5.30001 6.69687 4.98279C7.88152 4.51294 10.6925 4.62137 12.0017 4.62137C13.3108 4.62137 16.1259 4.51694 17.3065 4.98279C18.0936 5.29601 18.7 5.90238 19.0172 6.69349C19.4871 7.87814 19.3786 10.6892 19.3786 11.9983C19.3786 13.3075 19.4871 16.1225 19.0172 17.3031C18.704 18.0902 18.0976 18.6966 17.3065 19.0138C16.1219 19.4837 13.3108 19.3752 12.0017 19.3752ZM16.8045 8.27167C17.3989 8.27167 17.8808 7.79382 17.8808 7.19546C17.8808 6.60113 17.3989 6.11924 16.8045 6.11924C16.2102 6.11924 15.7283 6.60113 15.7283 7.19546C15.7283 7.78978 16.2062 8.27167 16.8045 8.27167ZM12.0017 16.6124C14.5557 16.6124 16.6158 14.5523 16.6158 11.9983C16.6158 9.44428 14.5557 7.38421 12.0017 7.38421C9.44766 7.38421 7.38759 9.44428 7.38759 11.9983C7.38759 14.5523 9.44766 16.6124 12.0017 16.6124ZM12.0017 14.9981C10.3512 14.9981 9.00193 13.6528 9.00193 11.9983C9.00193 10.3438 10.3472 8.99855 12.0017 8.99855C13.6562 8.99855 15.0014 10.3438 15.0014 11.9983C15.0014 13.6528 13.6522 14.9981 12.0017 14.9981Z"
                                    fill="#63666A"></path>
                            </svg> */}
                    </a>
                </div>)}
                <div className="row">
                    {data.footer_header?.map((footer, i) => (
                        <div className="col footertext" key={i}>
                            <h6 className={`text-fw500 ${data.type}`}>{footer.header_name}</h6>
                            {footer.nested_values?.map((nested, j) => (
                                <span key={j}>
                                    {isSocialMedia(footer.header_name) ? (
                                        <a
                                            className="texthrlink ct-footer-list-header mr-10"
                                            href={nested.hrefLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {socialMediaIcons[nested.value] || null}
                                        </a>
                                    ) : (
                                        <div>
                                            <a
                                                className={`${data.type} texthrlink ct-footer-list-header`}
                                                href={nested.hrefLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {nested.value}
                                            </a>
                                            <br />
                                        </div>
                                    )}
                                </span>
                            ))}
                        </div>
                    ))}

                </div>
                <br />
                {data.type !== 'fluentcommerce' ? <div>
                    <div className="line1 newfooter"></div>
                    <br />
                    <div className="row  newfooter">
                        <div className="col-lg-6 col-sm-6 col-md-6 foot-contact text-left">
                            <a className="footertext1" href="https://fenixcommerce.com/" target="_blank" rel="noopener noreferrer">
                                <img src={Fenixlogo} className="fenix-logo logo-foot" alt="fenixcommerce" />
                            </a>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-md-6 foot-contact text-right">
                            <p className="footer-align">
                                <a className="terms-of-service footer_link f-14" href=" https://fenixcommerce.com/terms-of-service/"
                                    target="_blank" rel="noopener noreferrer">
                                    Terms of Service |
                                </a>
                                <a className="privacy-policy footer_link f-14" href="https://fenixcommerce.com/privacy-policy/"
                                    target="_blank" rel="noopener noreferrer"> Privacy
                                    Policy </a>
                            </p>
                        </div>
                    </div>
                </div> : ''}

            </div>
            <br />
            <div className={`accordion ${data.type} mobilefooter`} id="accordionExample">
                <div className="card full-width">
                    {data.footer_header?.map((footer, i) => {
                        return (
                            <div key={i}>
                                {!isSocialMedia(footer.header_name) ?
                                    (<div className={`card-header ${data.type}`} aria-expanded="true" key={i} onClick={(e) => {
                                        e.stopPropagation();
                                        const target = document.querySelector(`#collapse${i}`);
                                        if (target) {
                                            target.classList.toggle('show');
                                        }
                                    }}>
                                        <span
                                            className={`title ${data.type} text-fw500`}>
                                            {footer.header_name}
                                        </span>
                                        <span
                                            className="accicon">
                                            <ExpandMoreIcon />
                                        </span>
                                        <div id={`collapse${i}`} className="collapse" data-bs-parent="#accordionExample">
                                            {footer.nested_values?.map((nested, j) => {
                                                return (
                                                    <div className={`card-body ${data.type}`} key={j}>
                                                        <a
                                                            className={`texthrlink ${data.type} ct-footer-list-header`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                window.open(nested.hrefLink, '_blank');
                                                            }}
                                                            href={nested.hrefLink}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {nested.value}
                                                        </a>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    ) :
                                    (<div className='sm-icons'>
                                        {footer.nested_values?.map((nested, j) => {
                                            return (
                                                <span key={j}>
                                                    <a className="texthrlink ct-footer-list-header mr-10 mb-10" href={nested.hrefLink} target="_blank" rel="noopener noreferrer">
                                                        {socialMediaIcons[nested.value] || null}
                                                    </a>
                                                </span>
                                            )
                                        })}
                                    </div>)
                                }
                            </div>
                        )
                    })}
                </div>
                {/* <br /> */}
                {data.type === 'janieandjack' && (<div>
                    <a className="texthrlink ct-footer-list-header m-10" href="https://www.facebook.com/janieandjack" target="_blank" rel="noopener noreferrer">
                        <svg width="28" height="28" viewBox="0 0 24 24">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M13.621 21V13.125H16.3447L16.8632 9.8674H13.621V7.75346C13.621 6.86227 14.0739 5.99356 15.526 5.99356H17V3.22007C17 3.22007 15.6624 3 14.3835 3C11.7134 3 9.96807 4.56023 9.96807 7.38467V9.8674H7V13.125H9.96807V21H13.621Z"
                                fill="#63666A"></path>
                        </svg>
                    </a>
                    <a className="texthrlink ct-footer-list-header m-10" href="https://www.instagram.com/janieandjack/" target="_blank" rel="noopener noreferrer">
                        <svg width="28" height="28" viewBox="0 0 24 24">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M15.7122 20.9334C17.1539 20.8651 18.4309 20.5358 19.4831 19.4797C20.5352 18.4275 20.8645 17.1505 20.9367 15.7089C21.0211 14.223 21.0211 9.77358 20.9367 8.28775C20.8685 6.84608 20.5392 5.56908 19.4831 4.51694C18.4309 3.46484 17.1539 3.13554 15.7122 3.06327C14.2264 2.97891 9.77292 2.97891 8.28709 3.06327C6.84946 3.1315 5.57245 3.4608 4.51632 4.51294C3.46018 5.56508 3.13488 6.84208 3.0626 8.28375C3.02466 8.95238 3.00379 10.2219 3 11.5516V12.441C3.00379 13.7707 3.02466 15.0402 3.0626 15.7089C3.13088 17.1505 3.46018 18.4275 4.51632 19.4797C5.57245 20.5318 6.84546 20.8611 8.28709 20.9334C9.77292 21.0177 14.2264 21.0177 15.7122 20.9334ZM12.0017 19.3752C10.6925 19.3752 7.87752 19.4797 6.69687 19.0138C5.90979 18.7006 5.30339 18.0942 4.98617 17.3031C4.51632 16.1185 4.62475 13.3075 4.62475 11.9983C4.62475 10.6892 4.52032 7.87414 4.98617 6.69349C5.29939 5.90642 5.90575 5.30001 6.69687 4.98279C7.88152 4.51294 10.6925 4.62137 12.0017 4.62137C13.3108 4.62137 16.1259 4.51694 17.3065 4.98279C18.0936 5.29601 18.7 5.90238 19.0172 6.69349C19.4871 7.87814 19.3786 10.6892 19.3786 11.9983C19.3786 13.3075 19.4871 16.1225 19.0172 17.3031C18.704 18.0902 18.0976 18.6966 17.3065 19.0138C16.1219 19.4837 13.3108 19.3752 12.0017 19.3752ZM16.8045 8.27167C17.3989 8.27167 17.8808 7.79382 17.8808 7.19546C17.8808 6.60113 17.3989 6.11924 16.8045 6.11924C16.2102 6.11924 15.7283 6.60113 15.7283 7.19546C15.7283 7.78978 16.2062 8.27167 16.8045 8.27167ZM12.0017 16.6124C14.5557 16.6124 16.6158 14.5523 16.6158 11.9983C16.6158 9.44428 14.5557 7.38421 12.0017 7.38421C9.44766 7.38421 7.38759 9.44428 7.38759 11.9983C7.38759 14.5523 9.44766 16.6124 12.0017 16.6124ZM12.0017 14.9981C10.3512 14.9981 9.00193 13.6528 9.00193 11.9983C9.00193 10.3438 10.3472 8.99855 12.0017 8.99855C13.6562 8.99855 15.0014 10.3438 15.0014 11.9983C15.0014 13.6528 13.6522 14.9981 12.0017 14.9981Z"
                                fill="#63666A"></path>
                        </svg>
                    </a>
                </div>)}
                {data.type !== 'fluentcommerce' ? <div><div className="line1"></div>
                    <br />
                    <div className="row  ">
                        <div className="col-lg-6 col-sm-6 foot-contact ">
                            <a className="footertext1" href="https://fenixcommerce.com/" target="_blank" rel="noopener noreferrer">
                                <img src={Fenixlogo} className="fenix-logo logo-foot" alt="fenixcommerce" />
                            </a>
                        </div>
                        <div className="col-lg-6 col-sm-6 foot-contact text-right">
                            <p className="footer-align ">
                                <a className="terms-of-service footer_link f-12" href=" https://fenixcommerce.com/terms-of-service/" target="_blank" rel="noopener noreferrer"> Terms of Service | </a>
                                <a className="privacy-policy footer_link f-12" href="https://fenixcommerce.com/privacy-policy/" target="_blank" rel="noopener noreferrer"> Privacy Policy </a>
                            </p>
                        </div>
                    </div>
                </div> : ''}
            </div>
        </div>
    )
}

export default Footer
