import React, { useState, useEffect } from "react";
import { RETURNS_SEARCH, RETURNS_UI_URLS } from "../../../constants";
import dateFormat from 'dateformat';
import { styled } from '@mui/material/styles';
// import {
//     tablePaginationClasses as classes,
// } from '@mui/base/TablePagination';
import TablePagination from '@mui/material/TablePagination';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { FormControl, MenuItem, Select, Snackbar, Alert, InputAdornment, OutlinedInput, Button } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
import EmailDialog from "../email-dialog/email-select-dialog";

function ReturnedOrders(props) {
    const [date, setDateValue] = useState('3Months');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [filterData, setFilter] = useState([]);
    const [page, setPage] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [data1, setRetailerData] = React.useState({});
    const [showtooltip, setShowTooltip] = useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [snackbarProp, setSnackbarProp] = useState({});
    const [isLoaderEnable, setIsLoaderEnable] = useState(false);
    const [searchValuechange, setSearchValueChange] = React.useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [orderIdParam, setorderIdParam] = useState('');
    const [emailData, setEmailData] = useState('');



    // const [orderInfo, setOrderInfo] = useState({});
    let navigate = useNavigate();


    let filterObj = props.data ? props.data : null;
    let retailerInfo = props.retailerData ? props.retailerData : JSON.parse(sessionStorage.getItem('retailerData'));

    const handleClearSearch = () => {
        setSearchValue('');
        returnSearch(fromDate, toDate, null, '1', rowsPerPage);
    }

    const openEmailDialog = (returnId, shipId, emailId) => {
        let d = {
            shipId: shipId,
            returnId: returnId,
            emailId: emailId
        }
        setEmailData(d)
        setOpen(true);
    };

    const dialogClose = (value) => {
        if (value) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'success', msg: 'Notification sent to ' + value.recipients?.to[0] });
        }
        setOpen(false);
    };

    const [searchValueDummy, setSearchValueDummy] = useState('');
    const searchParams = (event) => {
        event.preventDefault();
        setSearchValueChange(false);
        if (searchValue.length > 3 || searchValue.length !== 0) {
            let filters = {
                "criteria_type": "Contains",
                "field_name": "rma_number",
                "field_type": "TEXT",
                "value": searchValue
            }
            setSearchValueDummy(JSON.parse(JSON.stringify(searchValue)));
            returnSearch(fromDate, toDate, filters, '1', rowsPerPage);
        }
        else {
            returnSearch(fromDate, toDate, null, '1', rowsPerPage);
        }
    }

    const valueChange = (e) => {
        setSearchValue(e.target.value);
        setSearchValueChange(true)

    }

    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let o = decodeURIComponent(params.get("ordernumber"));
        setOrderNumber(o);
        let orderId = params.get("orderid");
        setorderIdParam(orderId);
        let z = params.get("zipcode");
        setZipcode(z)
        setRetailerData(JSON.parse(sessionStorage.getItem('retailerData')));
        let d = formatDate(new Date().setDate(new Date().getDate() - 90));
        setFromDate(d);
        let d1 = formatDate(new Date());
        setToDate(d1);
        setSearchValue('');
        returnSearch(d, d1, null, '1', 10, o, z, orderId);
    }, [filterObj]);

    const returnSearch = async (from, to, params, pageNo, size, o, z, orderId) => {
        let d;
        setIsLoaderEnable(true);
        //    setOrderInfo(JSON.parse(sessionStorage.getItem('orderInfo'))); 
        //    let data = JSON.parse(sessionStorage.getItem('orderInfo'));     
        if (o && z) {
            d = {
                "filters": [
                    {
                        "criteria_type": "Equals",
                        "field_name": "order_info.order_number",
                        "field_type": "TEXT",
                        "value": o
                    },
                    {
                        "criteria_type": "Equals",
                        "field_name": "order_info.buyer_address.zip_code",
                        "field_type": "TEXT",
                        "value": z
                    }
                ],
                "search_params": []
            }
        }
        else if (orderId) {
            d = {
                "filters": [
                    {
                        "criteria_type": "Equals",
                        "field_name": "order_info.order_id",
                        "field_type": "TEXT",
                        "value": orderId
                    }
                ],
                "search_params": []
            }
        }
        else if (JSON.parse(sessionStorage.getItem('email'))) {
            d = {
                "filters": [
                    {
                        "criteria_type": "Greater than or equal",
                        "field_name": "requested_dttm.formatted_dttm",
                        "field_type": "TIMESTAMP",
                        "value": from + "T00:00:00-05:00"
                    },
                    {
                        "criteria_type": "Less than or equal",
                        "field_name": "requested_dttm.formatted_dttm",
                        "field_type": "TIMESTAMP",
                        "value": to + "T23:59:59-05:00"
                    },
                    {
                        "criteria_type": "Equals",
                        "field_name": "order_info.email_id",
                        "field_type": "TEXT",
                        "value": JSON.parse(sessionStorage.getItem('email'))
                    }

                ],
                "search_params": []
            }
        }
        if (params) {
            d.filters.push(params);
        }
        try {
            const response = await fetch(RETURNS_SEARCH.Return_Search + (pageNo ? pageNo : '1') + '&size=' + (size ? size : 10), {
                method: 'POST',
                body: JSON.stringify(d),
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
                }
            });
            if (!response) {
                setIsLoaderEnable(false);
                setFilter(null);
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            }
            if (response?.status === 200) {
                const result = await response.json();
                if (result?.data) {
                    setFilter(result);
                    setTotalCount(result?.meta?.total_count);
                    // setPageCount(result.meta?.current_count);
                    setPage(result.meta?.current_pages - 1);
                }
                else {
                    setFilter(null);
                    setTotalCount(0);
                }
                setIsLoaderEnable(false);
            }
            else {
                setFilter(null);
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
                setIsLoaderEnable(false);
                setTotalCount(0);
            }
        }
        catch (error) {
            setFilter(null);
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            setIsLoaderEnable(false);
            setTotalCount(0);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        let p = newPage + 1;
        setPage(p);
        returnSearch(fromDate, toDate, '', p, rowsPerPage);
        scrollToParticularSection('top-section');
        props.passChildData('pageChanged');
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        returnSearch(fromDate, toDate, '', '1', parseInt(event.target.value, 10));
    };

    const dateSelected = (event) => {
        setDateValue(event.target.value);
        setSearchValue('');
        if (event.target.value === '3Months') {
            let d = formatDate(new Date().setDate(new Date().getDate() - 90));
            setFromDate(d);
            let d1 = formatDate(new Date());
            setToDate(d1);
            setTimeout(() => {
                returnSearch(d, d1, '', '1', rowsPerPage);
            }, 1000);
        }
        else if (event.target.value === 'Last30days') {
            let d = formatDate(new Date().setDate(new Date().getDate() - 30));
            setFromDate(d);
            let d1 = formatDate(new Date());
            setToDate(d1);
            setTimeout(() => {
                returnSearch(d, d1, '', '1', rowsPerPage);
            }, 1000);
        }
        // else if (event.target.value === '6Months') {
        //     let d = formatDate(new Date().setDate(new Date().getDate() - 180));
        //     setFromDate(d);
        //     let d1 = formatDate(new Date());
        //     setToDate(d1);
        //     setTimeout(() => {
        //         returnSearch(d, d1);
        //     }, 1000);
        // }

    };

    const HtmlTooltipForAddress = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: theme.typography.pxToRem(12),
            border: '2px solid #dadde9',
        },
    }));
    const HtmlTooltipForDetails = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            // width: '400px',
            fontSize: theme.typography.pxToRem(12),
            border: '2px solid #dadde9',
        },
    }));

    const formatDate = (event) => {
        return (new Date(event)).toISOString().split('T')[0];;
    }

    const formatingDateForDisplay = (date) => {
        return dateFormat(date, "mmmm d, yyyy");
    }

    const removeunderscore = (returnstatus) => {
        let data = returnstatus?.replaceAll('_', ' ');
        return data?.toLowerCase().replace(/(?:^|[\s-/])\w/g, function (match) {
            return match?.toUpperCase();
        });;
    }

    const addDefaultImg = ev => {
        ev.target.src = "/no-img.jpg";
        ev.target.style = 'width:45%';
    }

    const scrollToParticularSection = (id) => {
        const violation = document.getElementById(id);
        violation?.scrollIntoView({
            block: 'start',
            // behavior: 'smooth',
        });
    }

    const genarateLabelURL = async (return_id, shipment_id,i,k) => {
        try {
            const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'returns/' + return_id + '/shipment/' + shipment_id + '/generate-label', {
                method: 'POST',
                body: {},
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
                }
            });
            if (!response) {
            }
            if (response?.status === 200) {
                const result = await response.json();
                if (result) {
                    if(filterData.data[i].return_shipment_summaries[k].label_summary){
                        filterData.data[i].return_shipment_summaries[k].label_summary.url=result?.url;
                    }
                    else{
                      let data = {
                        url:result?.url
                      }
                     filterData.data[i].return_shipment_summaries[k].label_summary = data;
                    }
                    setFilter(filterData);
                    setSnackOpen(true);
                    setSnackbarProp({ style: 'success', msg: 'Label Generated Successfully.' });
                }
            }
            else{
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Failed To Generate Label.' });
            }
        }
        catch (error) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Failed To Generate Label.' });
        }
    }
    const genaratePackageSlipURL = async (shipment_id,i,k) => {
        try {
            const response = await fetch(RETURNS_UI_URLS.ADMIN_BASE_URL + 'return-shipment/' + shipment_id + '/regenerate-packing-slip', {
                method: 'POST',
                body: {},
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
                }
            });
            if (!response) {
            }
            if (response?.status === 200) {
                const result = await response.json();
                if (result) {
                    filterData.data[i].return_shipment_summaries[k].packing_slip_url = result?.url;
                    setFilter(filterData)
                    setSnackOpen(true);
                    setSnackbarProp({ style: 'success', msg: 'Package Slip Generated Successfully.' })
                }
            }
            else{
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Failed To Generate Package Slip.' });
            }

        }
        catch (error) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Failed To Generate Package Slip.' });
        }
    }

    // const searchParams = (event) => {
    //     setSearchValue(event.target.value);
    //     if (event.target.value.length > 3 || event.target.value.length !== 0) {
    //         let filters = {
    //             "criteria_type": "Equals",
    //             "field_name": "rma_number",
    //             "field_type": "TEXT",
    //             "value": event.target.value
    //         }
    //         returnSearch(fromDate, toDate, filters,'1',rowsPerPage);
    //     }
    //     else {
    //         returnSearch(fromDate, toDate,'','1',rowsPerPage);
    //     }
    // }


    const gobacktologin = () => {
        // let data2 = JSON.stringify(data1.type);
        let data2 = '/login'
        navigate(data2.replace(/"/g, ""));
    }
    const NoIcon = styled('span')(({ theme }) => ({
        display: 'none',
    }));

    const handleTouchStart = () => {
        setShowTooltip(true);
    }
    const handleTouchEnd = () => {
        setShowTooltip(false)
    }

    const backspaceCall = (e) => {
        if (e.target.value.length === 0 && e.key === 'Backspace') {
            e.preventDefault();
            setSearchValue(e.target.value)
            searchParams(e)
        }
    }

    const displaySizeandColor = (size, color) => {
        return size && color ? (<span>Size: {size} &nbsp;&nbsp;|&nbsp;&nbsp; Color: {color}</span>) : (size && !color ? ('Size: ' + size) : (!size && color ? ('Color: ' + color) : ''))
    }

    // const blue = {
    //     200: '#A5D8FF',
    //     400: '#3399FF',
    // };

    // const grey = {
    //     50: '#F3F6F9',
    //     100: '#E5EAF2',
    //     200: '#DAE2ED',
    //     300: '#C7D0DD',
    //     400: '#B0B8C4',
    //     500: '#9DA8B7',
    //     600: '#6B7A90',
    //     700: '#434D5B',
    //     800: '#303740',
    //     900: '#1C2025',
    // };



    // const CustomTablePagination = styled(TablePagination)(
    //     ({ theme }) => `
    //     & .${classes.spacer} {
    //       display: none;
    //     }

    //     & .${classes.toolbar}  {
    //       display: flex;
    //       flex-direction: column;
    //       align-items: flex-start;
    //       gap: 8px;
    //       background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};

    //       @media (min-width: 768px) {
    //         flex-direction: row;
    //         align-items: center;
    //       }
    //     }

    //     & .${classes.selectLabel} {
    //       margin: 0;
    //     }

    //     & .${classes.select}{
    //       font-family: 'IBM Plex Sans', sans-serif;
    //       padding: 2px 0 2px 4px;
    //       border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    //       border-radius: 6px; 
    //       background-color: transparent;
    //       color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    //       transition: all 100ms ease;

    //       &:hover {
    //         background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    //         border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    //       }

    //       &:focus {
    //         outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    //         border-color: ${blue[400]};
    //       }
    //     }

    //     & .${classes.displayedRows} {
    //       margin: 0;

    //       @media (min-width: 768px) {
    //         margin-left: auto;
    //       }
    //     }

    //     & .${classes.actions} {
    //       display: flex;
    //       gap: 6px;
    //       border: transparent;
    //       text-align: center;
    //     }

    //     & .${classes.actions} > button {
    //       align-items: center;
    //       padding: 0;
    //       border: transparent;
    //       background-color: transparent;
    //       color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    //       transition: all 100ms ease;

    //       > svg {
    //         font-size: 22px;
    //       }

    //       &:hover {
    //         background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    //         border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    //       }

    //       &:focus {
    //         outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    //         border-color: ${blue[400]};
    //       }

    //       &:disabled {
    //         opacity: 0.3;
    //         &:hover {
    //           border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    //           background-color: transparent;
    //         }
    //       }
    //     }
    //     `,
    // );

    const renderForm = (
        <div className="container my-orders-wrapper" id="top-section">
            {sessionStorage.getItem('email') ? <div className="row px-16">
                <div className="align-center width-fit-content pl-10 pr-0">
                    {/* <label className="fw-600 width-fit-content f-14 mt-10 dark-grey-color">Your Order(s) {pageCount ? pageCount : '0'}</label> */}
                    {/* <label className={`fw-600 width-fit-content f-16 ${retailerInfo?.type}`}>Item(s) Returned <span className="f-14 bg-color pd-5 br-5">{totalCount ? totalCount : '0'}</span></label> */}
                    <label className={` width-fit-content mmt-5px f-16 ${retailerInfo?.type}`}> <span className="fw-600 pd-5 br-5">{totalCount ? totalCount : '0'} item(s)</span> returned in </label>

                </div>
                <div className="col">
                    {/* <input type="text" className=" p-10 br-5 search-border f-14 mm-t10"
                                        value={searchValue} onChange={searchParams} placeholder='Search with order number' /> */}
                    <FormControl sx={{ m: 0 }} className='width-150 mmt-5px'>
                        {/* <Select className="form-select p-10 search-border f-14" IconComponent={NoIcon} defaultValue={date} onChange={dateSelected}>
                                                    <MenuItem value={'Last30days'}>Last 30 days</MenuItem>
                                                    <MenuItem value={'3Months'}>Past 3 Months</MenuItem>
                                                </Select> */}
                        <Select className={`${data1.type} form-select p-10 search-border f-14 fw-500`} IconComponent={NoIcon} defaultValue={date} onChange={dateSelected}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper">
                            <MenuItem className={`${data1.type + '-menuitem'}`} value='Last30days'>Last 30 days</MenuItem>
                            <MenuItem className={`${data1.type + '-menuitem'}`} value='3Months'>Past 3 Months</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {/* <div className='col-md-2'></div> */}
                {sessionStorage.getItem('email') && !document.location.href.includes('my-orders/return') ?
                    <div className="col-12 col-sm-12 col-md-5 col-lg-4 text-right input-wrapper">
                        <form onSubmit={searchParams}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <OutlinedInput className={`p-10 fw-500 br-5 search-border mm-t16 f-14 ${data1?.type}`}
                                    id="outlined-adornment-amount" onKeyUp={e => backspaceCall(e)} inputProps={{ maxLength: 25, }} placeholder='Search with RMA number'
                                    value={searchValue} onChange={e => valueChange(e)}
                                    startAdornment={<InputAdornment position="start">
                                        <IconButton onClick={handleClearSearch} edge="end">
                                            {searchValue?.length > 0 ? <ClearIcon className="f-12" /> : null}
                                        </IconButton>
                                    </InputAdornment>}
                                    label="Amount"
                                    endAdornment={<InputAdornment position="end">
                                        <Button type='submit' disabled={!searchValuechange} ><ArrowForwardIcon className={`${!searchValuechange ? 'no-pointer-events cursor-not-allowed op-5' : 'cursor-pointer primary-color'}`} /></Button>
                                    </InputAdornment>}
                                />
                            </FormControl>
                        </form>
                    </div> : ''}
            </div> : ''}

            <div className="row px-16">
                <div className="col-12 card-wrap">
                    {filterData?.data && filterData?.data.length > 0 ? filterData?.data?.map((data, i) => (
                        <div key={i} >
                            <div className="card my-16">
                                <div className="row m-0 p-10 f-14 bg-color br-lf-rt-5 hide-m">
                                    <div className="col-md col-sm col-12 mdisplay-flex">
                                        <div className='color-text fw-500'>Order Placed</div>
                                        <div className={`fw-600 ${retailerInfo?.type} mm-l16 text-trans`}>{formatingDateForDisplay(data?.order_info?.placed_dttm?.actual_dttm)}</div>
                                    </div>
                                    {retailerInfo?.type !== 'janieandjack' ? <div className="col-md col-sm col-12 mdisplay-flex">
                                        <div className='color-text fw-500'>Total Refund</div>
                                        <div className={`fw-600 ${retailerInfo?.type} mm-l16`}>$ {data?.refund_amount?.amount ? data?.refund_amount?.amount.toFixed(2) : '0'}</div>
                                    </div> : ''}
                                    <div className="col-lg col-md col-sm col-12 mdisplay-flex">
                                        <div className='width-fit-content'>
                                            <HtmlTooltipForAddress enterTouchDelay={0}
                                                title={
                                                    <React.Fragment>
                                                        <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} >
                                                            <div className="fw-600 f-14">{data?.order_info?.buyer_address?.name}</div>
                                                            <div className="fw-400 f-12">{data?.order_info?.buyer_address?.address1}</div>
                                                            <div className="fw-400 f-12">{data?.order_info?.buyer_address?.city},&nbsp;{data?.order_info?.buyer_address?.province}</div>
                                                            <div className="fw-400 f-12">{data?.order_info?.buyer_address?.country}, &nbsp;{data?.order_info?.buyer_address?.zip_code}</div>
                                                        </div>
                                                    </React.Fragment>
                                                }>
                                                <span className='mdisplay-flex'>
                                                    <div className='color-text fw-500'>Ship to</div>
                                                    <div className={`fw-600 ${retailerInfo?.type} cursor-pointer mm-l16`}>{data?.order_info?.buyer_address?.name} <span className="text-color">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                                        </svg></span>
                                                    </div></span>
                                            </HtmlTooltipForAddress>
                                        </div>
                                    </div>
                                    <div className="col-lg col-md col-sm col-12 mdisplay-flex">
                                        <div className='color-text fw-500'>Order Number</div>
                                        <div className={`fw-600 ${retailerInfo?.type} mm-l16`}>{data?.order_info?.order_number}</div>
                                    </div>
                                    <div className="col-lg col-md col-sm col-12 mdisplay-flex">
                                        <div className='color-text fw-500'>RMA#</div>
                                        <div className={`fw-600 ${retailerInfo?.type} mm-l16`}>{data.rma_number}</div>
                                    </div>
                                </div>
                                <div className='d-md-none m-0 p-16 f-14 bg-color br-lf-rt-5'>
                                    <div className='color-text fw-500'>RMA#:&nbsp;
                                        <HtmlTooltipForAddress enterTouchDelay={0}
                                            title={
                                                <React.Fragment>
                                                    <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                        {/* <div className="row m-0 p-10 f-14 bg-color br-lf-rt-5" key={i}>
                                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                            <div className='color-text fw-500'>Order Number: </div>
                                                            <div className={`fw-600 ${retailerInfo?.type} mm-l16`}>{data?.order_info?.order_number}</div>
                                                        </div>
                                                        <div className="col-md-3 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                            <div className='color-text fw-500'>Order Placed: &nbsp;</div>
                                                            <div className={`fw-600 ${retailerInfo?.type} mm-l16 text-trans`}>{formatingDateForDisplay(data?.order_info?.placed_dttm?.actual_dttm)}</div>
                                                        </div>
                                                        {retailerInfo?.type !== 'janieandjack' ? <div className="col-md-2 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                            <div className='color-text fw-500'>Total Refund: &nbsp;&nbsp;</div>
                                                            <div className={`fw-600 ${retailerInfo?.type} mm-l16`}>$ {data?.refund_amount?.amount ? data?.refund_amount?.amount : '0'}</div>
                                                        </div> : ''}
                                                        <div className="col-md-4 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                            <div className='color-text fw-500'>Ship to: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </div>
                                                            <div className={`fw-600 ${retailerInfo?.type} cursor-pointer mm-l16`}>{data?.order_info?.buyer_address.name} </div>
                                                        </div>
                                                    </div> */}
                                                        <div>Order Placed : &nbsp;{formatingDateForDisplay(data?.order_info?.placed_dttm?.actual_dttm)}</div>
                                                        <div>Order Number : &nbsp;{data?.order_info?.order_number}</div>
                                                        {retailerInfo?.type !== 'janieandjack' ? <div>Total Refund : &nbsp;$ {data?.refund_amount?.amount ? data?.refund_amount?.amount.toFixed(2) : '0'}</div> : ''}
                                                        <div>Ship to : &nbsp;{data?.order_info?.buyer_address?.name}</div>
                                                    </div>
                                                </React.Fragment>}>
                                            <span
                                                className={`fw-600 ${retailerInfo?.type} text-underline`}>{data.rma_number}</span>
                                        </HtmlTooltipForAddress>
                                    </div>
                                </div>
                                <div className="row line m-0">
                                    <div className="col-md-12 p-10 mt-10">
                                        <div className="f-14 fw-700 px-12 dark-grey-color">
                                            {(removeunderscore(data?.status))?.charAt(0).toUpperCase() + (removeunderscore(data?.status))?.slice(1).toLowerCase() + ' on '}{formatingDateForDisplay(data?.requested_dttm?.formatted_dttm)}
                                        </div>
                                    </div>
                                    {(data.return_shipment_summaries && data.return_shipment_summaries.length > 0) ? data.return_shipment_summaries.map((lData, k) => {
                                        return (
                                            <div>
                                                {lData?.vendor ? <div className="f-14 fw-400 pl-10-pr-12">
                                                    Product(s) Sold and Shipped by: <span className={`fw-600 ${data1.type}`}>{lData?.vendor}</span>
                                                </div> : ''}
                                                <div key={k} className={`row ml-0 mt-10 mb-10 px-0 ${data.return_shipment_summaries.length > k + 1 ? 'bottom-dashed-line' : ''}`}>
                                                    <div className={`col-xs-9 col-sm-9 col-md-9`}>
                                                        {lData?.line_items?.map((linedata, j) => {
                                                            return (
                                                                <div className="row " key={j}>
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-2 text-center">
                                                                        {data1.type !== 'fluentcommerce' ?
                                                                            <span>
                                                                                <img className={`img-block border-image mb-10 cursor-pointer ${!linedata.line_item?.image_url ? "width-45per" : ""}`} alt='linedata.line_item?' onClick={() => window.open(linedata.line_item?.routing_url ? linedata.line_item?.routing_url : retailerInfo.store_url)}
                                                                                    src={linedata.line_item?.image_url ? `${linedata.line_item?.image_url}` : '/no-img.jpg'}
                                                                                    onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                            </span> :
                                                                            <span>
                                                                                <img className={`img-block border-image mb-10 cursor-pointer ${!linedata.line_item?.image_url ? "width-45per" : ""}`} alt='linedata.line_item?' onClick={() => window.open(retailerInfo.store_url)}
                                                                                    src={linedata.line_item?.image_url ? `${linedata.line_item?.image_url}` : '/no-img.jpg'}
                                                                                    onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                            </span>}
                                                                    </div>
                                                                    <div className="col-lg-9 col-md-9 col-9 col-sm-9 mm-l16">
                                                                        {linedata.line_item?.product_name ? <div className={`fw-500 f-14 ${retailerInfo?.type} cursor-pointer mb-5px ${linedata.line_item?.product_name?.length > 60 ? '' : 'width-fit-content'}`} >
                                                                            <HtmlTooltipForDetails enterTouchDelay={0}
                                                                                title={
                                                                                    <React.Fragment>
                                                                                        <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                                                            {linedata.line_item?.product_name !== null && linedata.line_item?.product_name ?
                                                                                                <div>Product Name : &nbsp;{linedata.line_item?.product_name}</div> : ""}
                                                                                            {/* {linedata.line_item?.product_description !== null && linedata.line_item?.product_description ?
                                                                                            <div>Description : &nbsp;{linedata.line_item?.product_description}</div> : ""} */}
                                                                                            {linedata.line_item?.quantity !== null && linedata.line_item?.quantity >= 0 ?
                                                                                                <div>Ordered Qty : &nbsp;{linedata.line_item?.quantity}</div> : ''}
                                                                                            {linedata.line_item?.returnable_quantity !== null && linedata.line_item?.returnable_quantity >= 0 ?
                                                                                                <div>Returnable Qty : &nbsp;{linedata.line_item?.returnable_quantity}</div> : ''}
                                                                                        </div>
                                                                                    </React.Fragment>}>
                                                                                {showtooltip || (
                                                                                    <span className='mdisplay-flex'>
                                                                                        <div className='display-ellipsis'>{linedata.line_item?.product_name}</div>
                                                                                    </span>
                                                                                )}
                                                                            </HtmlTooltipForDetails>
                                                                        </div> :
                                                                            <div className={`fw-500 f-14 ${data1?.type}`}>{linedata.line_item?.sku}</div>}
                                                                        {(linedata?.line_item?.color || linedata?.line_item?.size) ? <div className='row mb-5px f-12 '>
                                                                            <div className='col-12 dark-grey-color fw-500'> {displaySizeandColor(linedata?.line_item?.size, linedata?.line_item?.color)}</div>
                                                                        </div> : ''}
                                                                        {/* <div className="fw-500 f-14 grey-color">{data?.line_item.product_description}</div> */}
                                                                        {/* {linedata.line_item??.routing_url ?  */}
                                                                        <div className='row'>
                                                                            <label className="mb-16 f-12 display-flex align-items-center qty-wrap custom-select">Qty:&nbsp;&nbsp;{linedata.line_item?.quantity}</label>
                                                                            {/* <button className={`${data1.type} tertiary-btn w-125`} onClick={() => window.open(data?.line_item.routing_url)}>Buy it again </button>
                                                  <button className=" secondary-btn w-125 ml-10" onClick={() => window.open(data?.line_item.routing_url)}>View your item </button> */}
                                                                        </div>
                                                                        {/* : ''} */}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 ">
                                                        <div className='row text-right mr-10 mm-x0'>
                                                            {lData?.label_summary?.url ? <button className={`${data1?.type ? data1.type + '-btn' : ''} mb-10 float-right`} onClick={() => window.open(lData?.label_summary?.url)}>Download Label</button> :
                                                                <button className={`${data1?.type ? data1.type + '-btn' : ''} mb-10 float-right`} onClick={() => genarateLabelURL(data.return_id, lData.id,i,k)}>Generate Label</button>}
                                                            {lData?.label_summary?.url ? (lData?.packing_slip_url? <button className={`${data1.type ? data1.type + '-btn' : ''} mb-10 float-right`} onClick={() => window.open(lData?.packing_slip_url , "download")}>Package Slip </button> :
                                                                <button className={`${data1.type ? data1.type + '-btn' : ''} mb-10 float-right`} onClick={() => genaratePackageSlipURL(lData.id,i,k)}>Generate Package Slip </button>) : ''}
                                                           
                                                            {(lData?.label_summary?.url && lData?.packing_slip_url) ? <button className={` ${data1.type} tertiary-btn mb-16`} onClick={e => openEmailDialog(data.return_id, lData.id, data?.order_info?.email_id)}>Send Email</button> : ''}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                    }
                                    ) : <div className='f-14 fw-500 text-center mt-16 mb-20'>No Line Items Found </div>}
                                </div>

                            </div>
                        </div>
                    )) : filterData === null || filterData.data === null ?
                        <div className={`${data1.type} f-14 fw-500 text-center min-height-50 align-center mt-16`}>
                            <div className='no-orders-border-line p-20'>
                                Welcome to <span className="fw-700">{data1?.vendorName}</span> Return Portal.
                                {searchValueDummy ?
                                    <div>It looks like you don’t have an order for <span className="fw-700 display-ellipsis">RMA: {searchValueDummy}</span></div>
                                    : (sessionStorage.getItem('email') ? <div>It looks like you don’t have any orders for <span className="fw-700 display-ellipsis">{JSON.parse(sessionStorage.getItem('email'))}</span></div>
                                        : (orderNumber ? <div>It looks like you don’t have an order for <span className="fw-700 display-ellipsis">Order Number:  {orderNumber}  & Zipcode:  {zipcode}</span></div> : <div>It looks like you don’t have an order for <span className="fw-700 display-ellipsis">Order Id:  {orderIdParam}</span></div>))}
                                <div><a href={() => false} className={`${data1.type} fw-700 cursor-pointer`} onClick={gobacktologin}>Logout</a></div> </div></div>
                        : ''}
                </div>
            </div>

            {totalCount > 10 ?
                <div className='pagination float-right mb-16 pgr-10 color-text fw-500'>
                    <TablePagination
                        component="div"
                        count={totalCount}
                        page={page}
                        className={`${data1?.type} table-page`}
                        showFirstButton
                        showLastButton
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        slots={{
                            firstPageIcon: FirstPageRoundedIcon,
                            lastPageIcon: LastPageRoundedIcon,
                            nextPageIcon: ChevronRightRoundedIcon,
                            backPageIcon: ChevronLeftRoundedIcon,
                        }}
                        slotProps={{
                            select: {
                                'aria-label': 'Rows per page',
                            },
                        }}
                    />
                </div> : ''
            }
            <EmailDialog open={open} emailData={emailData}
                onClose={dialogClose} />
            <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Alert
                    onClose={handleClose}
                    severity={snackbarProp.style}
                    variant="filled"
                    sx={{ width: '100%' }}
                >{snackbarProp.msg}
                </Alert></Snackbar>
        </div>
    )
    return (
        <div>
            {isLoaderEnable && !searchValue ?
                // <div className="loading">
                <div className="min-height-50 align-center loader">Loading your returns...</div>
                //  </div>
                : <div>{renderForm}</div>
            }
        </div>
    );
}

export default ReturnedOrders;