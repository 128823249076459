import { useEffect, useState } from "react";
import Footer from "../footter/footer";
import Navbar from "../navbar/navbar";
// import ReturnItem from "../return portal/fenix orders/retrun item/return-item";
// import ReturnedOrders from "../return portal/fenix orders/returned-orders/returned-orders";
import Loginportal from "../return portal/return-portal";
import Fenixorder from "../return portal/fenix orders/fenix-order";
import { useNavigate } from "react-router-dom";
// import BestSellers from "../best-sellers/best-sellers";
// import MyOrders from "../return portal/fenix orders/my-orders/my-orders";



function FluentCommerce(){
    const [showDelayedFile,setShowDelayedFile] = useState(false);
    const navigate = useNavigate();    

    let obj ={
        type:'fluentcommerce',
        fileUrl:'fluent-commerce/fluentcommerce-logo.png',
        vendorName:'Fluent Commerce',
        menuName:[{'menu':'Apparel','hrefLink':'https://fluentcommerce.com/'},{'menu':'Footwear','hrefLink':'https://fluentcommerce.com/'},{'menu':'Electronics','hrefLink':'https://fluentcommerce.com/'},{'menu':'Industries','hrefLink':'https://fluentcommerce.com/resources/digital-resources/7-unique-challenges-of-luxury-fulfillment/'},{'menu':'Resources','hrefLink':'https://fluentcommerce.com/resources/digital-resources/order-management-system-selection-43-smart-questions-to-ask-a-vendor/'},{'menu':'Customers','hrefLink':'https://fluentcommerce.com/resources/case-studies/breitling-case-study/'},{'menu':'Partners','hrefLink':'https://fluentcommerce.com/partners/solution/'},{'menu':'About','hrefLink':'https://fluentcommerce.com/about/'},
            ],
        login_img:'janieandjack/janieandjack.png',
        storeName:'fluentcommerce-sandbox',
        tenantId:'415d867c8924494195006dbcc877973d',
        footer_header:[{'header_name':'Products','nested_values':[{'value':'Fluent Big Inventory','hrefLink':'https://fluentcommerce.com/product/fluent-big-inventory/'},{'value':'Global Inventory','hrefLink':'https://fluentcommerce.com/product/fluent-big-inventory/'},{'value':'Order Management','hrefLink':'https://fluentcommerce.com/product/'},{'value':'Product Availability','hrefLink':'https://fluentcommerce.com/product/fluent-order-promising/'},]},
            {'header_name':'Use Cases','nested_values':[{'value':'Click & Collect','hrefLink':'https://fluentcommerce.com/solutions/click-collect/'},{'value':'Customer Service','hrefLink':'https://fluentcommerce.com/solutions/customer-service/'},{'value':'In-Store Pick and Pack','hrefLink':'https://fluentcommerce.com/solutions/in-store-pick-and-pack/'},{'value':'Headless Commerce','hrefLink':'https://fluentcommerce.com/solutions/headless-commerce/'},{'value':'Fulfillment Optimization','hrefLink':'https://fluentcommerce.com/solutions/fulfillment-optimization/'},]},
            {'header_name':'Resources','nested_values':[{'value':'Blog','hrefLink':'https://fluentcommerce.com/resources/blog/'},{'value':'Case Study','hrefLink':'https://fluentcommerce.com/resources/blog/resources/by-type/case-studies/'},{'value':'eBook','hrefLink':'https://fluentcommerce.com/resources/blog/resources/by-type/solution-briefs-ebooks/'},{'value':'Contact US','hrefLink':'https://fluentcommerce.com/contact-us/'}]},
            // {'header_name':'Company','nested_values':[{'value':'About','hrefLink':'https://fluentcommerce.com/about/'},{'value':'Customers','hrefLink':'https://fluentcommerce.com/customers/brands/'},{'value':'Successful projects','hrefLink':'https://fluentcommerce.com/customers/go-lives/'},{'value':'Partners','hrefLink':'https://fluentcommerce.com/partners/'},{'value':'Careers','hrefLink':'https://careers.fluentcommerce.com/'}]},
            {'header_name':'Stay Connected','nested_values':[{'value':'LinkedIn', 'hrefLink':'https://www.linkedin.com/company/fluent-commerce/'},{'value':'Youtube', 'hrefLink':'https://www.youtube.com/channel/UCl1XKol5goYSAjkEmnCPz3g'}]}  
        ],
        recommendedProducts:[{'image_url':'janieandjack/janiejack-1.png','title':'Floral Flutter Sleeve','price':'$38.32'},{'image_url':'janieandjack/horsesweater.png','title':'Horse Sweater','price':'$ 59.00'},
            {'image_url':'janieandjack/frenchterryhooded.png','title':'French Terry Hooded SweatShirt','price':'$ 49.00'},{'image_url':'janieandjack/stripedslubtee.png','title':'Stripped Slub Tee','price':'$ 59.00'}
        ],
        store_url:'https://fluentcommerce.com/',
        policyUrl:'https://fluentcommerce.com/'

    }
    sessionStorage.setItem('retailerData', JSON.stringify(obj));  
      
    useEffect(() => {
        setTimeout(() => {
            setShowDelayedFile(true);
        }, 1000);
        let tmp = document.location.href.slice(document.location.href?.lastIndexOf("/") , document.location.href?.length) ;        
        if(tmp === '/'  || document.location.href.includes('login') || (!document.location.search && !sessionStorage.getItem('email'))) {
            let data2 = ''; 
            sessionStorage.removeItem('returnItemData');
            sessionStorage.removeItem('selectedReasonData');
            sessionStorage.removeItem('selectedDummyReasonData');
            sessionStorage.removeItem('isSelectedLineItems');
            sessionStorage.removeItem('RPI');
            sessionStorage.removeItem('email'); 
            sessionStorage.removeItem('tabIndex');
            sessionStorage.removeItem('orderInfo');
            sessionStorage.removeItem('retailername');
            data2 = '/login';
            navigate(data2.replace(/"/g, "")); 
        } 
       },[navigate,obj.type]);
    
    return (
        <div className="hidden-overflow">
            <Navbar data={obj}></Navbar>
            {(document.location.search || sessionStorage.getItem('email')) && !(document.location.href.includes('login')) ? <Fenixorder data={JSON.parse(JSON.stringify(obj))}></Fenixorder> : <Loginportal data={JSON.parse(JSON.stringify(obj))}></Loginportal>}
            {/* <BestSellers data={obj}></BestSellers> */}
            {showDelayedFile && (
            <Footer data={obj}></Footer>
            )}
        </div>
      );
}

export default FluentCommerce