import React from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OrderPlaced from './order-placed/order-placed'
import MyOrders from './my-orders/my-orders'
import ReturnedOrders from './returned-orders/returned-orders'
import { useState, useEffect } from "react";
// import Navbar from "../../navbar/navbar";
// import Footer from "../../footter/footer";
import SimpleDialog from "./controlled-popup/popup";
import { useNavigate } from "react-router-dom";
// import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import ClearIcon from '@mui/icons-material/Clear';
// import IconButton from '@mui/material/IconButton';


function Fenixorder(data1) {
    let obj = data1.data;
    const [value, setValue] = React.useState('1');
    const [open, setOpen] = React.useState(false);
    // const [searchValue, setSearchValue] = useState([]);
    const navigate = useNavigate();    
    const [filterObjForMyreturns, setFilterObjForMyreturns] = useState(null);
    const [filterObjForMyorders, setFilterObjForMyorders] = useState(null);
    const [indexforsametab, setindexforsametab] = useState(0);

    useEffect(() => { 
        let data2='';
        // let data2 = JSON.stringify(obj.type);         
            if(document.location.href.includes('login') || (sessionStorage.getItem('retailername') ? (obj.type !== sessionStorage.getItem('retailername')): false)) {
                sessionStorage.removeItem('returnItemData');
                sessionStorage.removeItem('selectedReasonData');
                sessionStorage.removeItem('selectedDummyReasonData');
                sessionStorage.removeItem('isSelectedLineItems');
                sessionStorage.removeItem('RPI');
                sessionStorage.removeItem('email'); 
                sessionStorage.removeItem('tabIndex');
                sessionStorage.removeItem('orderInfo');
                data2 = '/login';
                navigate(data2.replace(/"/g, "")); 
            } 
            else if (document.location.href.includes('my-orders/return') && sessionStorage.getItem('RPI')) {
            data2 = ('/my-orders/return'+(document.location.search ? document.location.search :''));
            navigate(data2.replace(/"/g, "")); 
            } 
            else if (document.location.href.includes('my-orders')) {
            setValue('1');
            sessionStorage.removeItem('RPI');
            sessionStorage.setItem('tabIndex', JSON.stringify('1'));
            if(document.location.search.includes('ordernumber') || document.location.search.includes('orderid')){
            sessionStorage.removeItem('email'); 
            data2 = ('/my-orders'+(document.location.search ? document.location.search :''));
            }else{
            data2 = ('/my-orders');
            }
            navigate(data2.replace(/"/g, "")); 
        }
        else if(document.location.href.includes('my-returns')){
            setValue('2');
            sessionStorage.removeItem('RPI');
            sessionStorage.setItem('tabIndex', JSON.stringify('2'));
            if(document.location.search.includes('ordernumber') || document.location.search.includes('orderid')){
                sessionStorage.removeItem('email'); 
                data2 = ('/my-returns'+(document.location.search ? document.location.search :''));
            }else{
                data2 = ('/my-returns');
            }
            navigate(data2.replace(/"/g, "")); 
        }      
        // sessionStorage.removeItem('isSelectedLineItems');
        //     window.history.pushState(null, null, window.location.href);
        //     window.onpopstate = function (event) {
        //       navigate(1);
        //   }
        sessionStorage.setItem('retailername',obj.type)

        childData(sessionStorage.getItem('tabIndex') ? JSON.parse(sessionStorage.getItem('tabIndex')) : '1');
    }, [navigate,obj.type]);

    const handleChange = (event, newValue) => {        
        if (sessionStorage.getItem('isSelectedLineItems') ? !JSON.parse(sessionStorage.getItem('isSelectedLineItems')) : true) {
            if ((sessionStorage.getItem('selectedDummyReasonData') && sessionStorage.getItem('selectedReasonData')) ? (JSON.stringify(sessionStorage.getItem('selectedDummyReasonData')) === JSON.stringify(sessionStorage.getItem('selectedReasonData'))) : true) {
                sessionStorage.removeItem('returnItemData');
                setTimeout(() => {
                    setValue(newValue);
                    sessionStorage.setItem('tabIndex', JSON.stringify(newValue));
                }, 500);        
                if (newValue === '2') {
                    let data2 = '';
                    // let data2 = JSON.stringify(obj.type);  
                    data2 = ('/my-returns'+(document.location.search ? document.location.search :''));
                    navigate(data2.replace(/"/g, "")); 
                }
                else {
                    let data2 = '';
                    // let data2 = JSON.stringify(obj.type);  
                    data2 = ('/my-orders'+(document.location.search ? document.location.search :''));
                    navigate(data2.replace(/"/g, ""));
                }
                sessionStorage.removeItem('returnItemData');
                sessionStorage.removeItem('selectedReasonData');
                sessionStorage.removeItem('selectedDummyReasonData');
                sessionStorage.removeItem('isSelectedLineItems');
                sessionStorage.removeItem('RPI');
            } else {
                openDialog();
            }
        } else {
            openDialog();

        }
        // setSearchValue ('');
        setFilterObjForMyorders(null);
        setFilterObjForMyreturns(null);
    };
    const openDialog = () => {
        setOpen(true);
    };
    const dialogClose = (value) => {
        setOpen(false);
        if (value) {
            let data2 = '';
            data2 = ('/my-returns'+(document.location.search ? document.location.search :''));
            navigate(data2.replace(/"/g, ""));
            setValue('2');
            sessionStorage.setItem('tabIndex', JSON.stringify('2'));
            sessionStorage.removeItem('returnItemData');
            sessionStorage.removeItem('selectedReasonData');
            sessionStorage.removeItem('selectedDummyReasonData');
            sessionStorage.removeItem('isSelectedLineItems');
            sessionStorage.removeItem('RPI');
            // let currentPath = window.location.href;
            // window.location.href = currentPath.replace("my-orders", "my-returns");

        }
    }
    const childData = (data) => {
        if(data === '2'){
        setValue(data ? data : '2');
        }
        // else{
        //  setFilterObjForMyorders(null);
        //  setFilterObjForMyreturns(null);
        // }
    }

    const tabCliked = (data) => {
        setindexforsametab(indexforsametab+1)
        if(value === '1' && data === 'orders' && !sessionStorage.getItem('RPI')){
            setFilterObjForMyorders('sameTabClicked'+(indexforsametab))
        }else if(value === '2' && data === 'returns'){
            setFilterObjForMyreturns('sameTabClicked'+(indexforsametab))
        }
        
    }
    // const handleClearSearch= () => {
    //     setSearchValue ('');
    //     setFilterObjForMyorders(null);
    //     setFilterObjForMyreturns(null);
    // }

    // const searchParams = (event) => {
    //     setSearchValue(event.target.value);
    //     if (event.target.value.length > 3 || event.target.value.length !== 0) {
    //         if(value === '1'){
    //         let filters = {
    //             "criteria_type": "Equals",
    //             "field_name": "order_number",
    //             "field_type": "TEXT",
    //             "value": event.target.value
    //         }
    //         setFilterObjForMyorders(filters);
    //     }else{
    //         let filters = {
    //             "criteria_type": "Equals",
    //             "field_name": "rma_number",
    //             "field_type": "TEXT",
    //             "value": event.target.value
    //         }
    //         setFilterObjForMyreturns(filters);  
    //     }
    //     }
    //     else {
    //         setFilterObjForMyorders(null);
    //         setFilterObjForMyreturns(null);
    //     }
    // }

    // let obj = JSON.parse(sessionStorage.getItem('retailerData'))
    const renderForm = (
        <div>
            {/* <Navbar data={obj}></Navbar> */}
            <div>
                <Box sx={{ width: '100%', typography: 'body1' }} width="100%">
                    <TabContext value={value}>
                        <div className="container">
                        <div className="row px-16 mt-2rem">
                                <div className={`col-md-8 col-sm-12 card-wrap-fo ${obj?.type}`}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                                            <Tab label="My Orders" onClick={e => tabCliked('orders')} data={obj} value="1" />
                                            <Tab label="My Returns" onClick={e => tabCliked('returns')} data={obj} value="2" />
                                        </TabList>
                                    </Box>
                                </div>
                                {/* {sessionStorage.getItem('email') && !document.location.href.includes('my-orders/return') ? <div className="col-12 col-sm-12 col-md-4 text-right input-wrapper">
                                    <FormControl fullWidth sx={{ m: 1 }}>
                                        <OutlinedInput className={`p-10 fw-500 br-5 search-border f-14 ${obj?.type}`}
                                            id="outlined-adornment-amount" placeholder={value === '1' ? 'Search with order number' : 'Search with RMA number' }
                                            value={searchValue} onChange={searchParams}
                                            startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                                            label="Amount"
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    onClick={handleClearSearch}
                                                    edge="end"
                                                  >
                                                    {searchValue.length > 0 ? <ClearIcon /> : null }
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                        />
                                    </FormControl>
                                </div>:''} */}
                        </div>
                        </div>
                        <TabPanel value="1">
                            <MyOrders passChildData={childData} retailerData={obj} data={filterObjForMyorders}/>
                        </TabPanel>
                        <TabPanel value="2">
                            <ReturnedOrders passChildData={childData} retailerData={obj} data={filterObjForMyreturns}/>
                        </TabPanel>
                        <TabPanel value="3">
                            <OrderPlaced />
                        </TabPanel>
                        <TabPanel value="4">
                            <div className="container">
                                <OrderPlaced />
                            </div>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
            {/* <BestSellers data={obj}></BestSellers> */}
            {/* <Footer data={obj}></Footer> */}
            <SimpleDialog
                open={open}
                onClose={dialogClose}
            />
        </div>
    )
    return (
        <div>
            {renderForm}
        </div>
    )


}
export default Fenixorder;