import { useEffect,useState } from "react";
import Footer from "../footter/footer";
import Navbar from "../navbar/navbar";
// import ReturnItem from "../return portal/fenix orders/retrun item/return-item";
// import ReturnedOrders from "../return portal/fenix orders/returned-orders/returned-orders";
import Loginportal from "../return portal/return-portal";
import Fenixorder from "../return portal/fenix orders/fenix-order";
import { useNavigate } from "react-router-dom";
// import BestSellers from "../best-sellers/best-sellers";
// import MyOrders from "../return portal/fenix orders/my-orders/my-orders";



function ShopSimon(){
    const [showDelayedFile, setShowDelayedFile] = useState(false);
    const navigate = useNavigate();    

    let obj ={
        type: 'shopsimon',
        vendorName: 'Shop Simon',
        fileUrl: 'shopsimon/ss-logo.svg',
        menuName: [ 
            // {'menu': 'Deals', 'hrefLink': 'https://shop.simon.com/collections/curated-deals' }, {'menu': 'Just In', 'hrefLink': 'https://shop.simon.com/collections/curated-just-in' }, {'menu': 'Brands', 'hrefLink': 'https://shop.simon.com/pages/brands' },{ 'menu': 'Designers', 'hrefLink': 'https://shop.simon.com/pages/luxe-designers' },
            { 'menu': 'Women', 'hrefLink': 'https://shop.simon.com/collections/women' }, { 'menu': 'Men', 'hrefLink': 'https://shop.simon.com/collections/men' }, { 'menu': 'Shoes', 'hrefLink': 'https://shop.simon.com/collections/shoes' }, { 'menu': 'Handbags', 'hrefLink': 'https://shop.simon.com/collections/women-handbags' },
        { 'menu': 'Kids', 'hrefLink': 'https://shop.simon.com/collections/kids' }, { 'menu': 'Home', 'hrefLink': 'https://shop.simon.com/pages/home' }, { 'menu': 'Beauty', 'hrefLink': 'https://shop.simon.com/collections/accessories-health-beauty' }, { 'menu': 'Preloved', 'hrefLink': 'https://shop.simon.com/collections/curated-preloved' }],
        login_img: 'shopsimon/shopsimon.jpg',
        storeName: 'shoppremiumoutlets.myshopify.com',
        tenantId: '4555cbac57524704afa53d3fe49a497d',
        recommendedProducts: [{ 'image_url': 'shopsimon/bag-1.png', 'title': 'Simplie Light weight Expandable', 'price': '$138.54' }, { 'image_url': 'shopsimon/redshirt.png', 'title': 'Eco Campania Tee', 'price': '$ 25.59' },
        { 'image_url': 'shopsimon/furniture-4.png', 'title': 'Loungie Cloudy Bean Bag', 'price': '$ 122.00' }, { 'image_url': 'shopsimon/cloth-3.png', 'title': 'Gaphic Series Vector Tank Top ', 'price': '$ 7.49' }
        ],
        footer_header: [{
            'header_name': 'Help', 'nested_values': [{ 'value': 'Help/FAQs?', 'hrefLink': 'https://support.shopsimon.com/hc/en-us' }, { 'value': 'Contact Us', 'hrefLink': 'https://shop.simon.com/pages/contact-us' }, { 'value': 'Shipping Policy', 'hrefLink': 'https://support.shopsimon.com/hc/en-us/articles/360051095694-Shipping-Policy' }, { 'value': 'Return Policy', 'hrefLink': 'https://support.shopsimon.com/hc/en-us/articles/360051106334-Return-Policy' },
            { 'value': 'Refund Policy', 'hrefLink': 'https://shop.simon.com/policies/refund-policy' }]
        },
        { 'header_name': 'About', 'nested_values': [{ 'value': 'About Us', 'hrefLink': 'https://shop.simon.com/pages/about-shop-simon' }, { 'value': 'Rewards', 'hrefLink': 'https://shop.simon.com/pages/rewards' }, { 'value': 'Influencer Program', 'hrefLink': 'https://shop.simon.com/pages/influencer' }, { 'value': 'Get the App', 'hrefLink': 'https://shop.simon.com/pages/app' }, { 'value': 'Livestreams', 'hrefLink': 'https://shop.simon.com/pages/live' }] },
        { 'header_name': 'Social Media', 'nested_values': [{ 'value': 'Instagram', 'hrefLink': 'https://www.instagram.com/shopsimon/' }, { 'value': 'Facebook', 'hrefLink': 'https://www.facebook.com/shopsimon.us' }, { 'value': 'Pinterest', 'hrefLink': 'https://www.pinterest.com/shopsimon/' }, { 'value': 'Tiktok', 'hrefLink': 'https://www.tiktok.com/@shopsimon' }] },
        ],
        store_url: 'https://shop.simon.com/',
        policyUrl: 'https://support.shopsimon.com/hc/en-us/articles/360051106334-Return-Policy'

    }
    sessionStorage.setItem('retailerData', JSON.stringify(obj));
    
    useEffect(() => {
        setTimeout(() => {
            setShowDelayedFile(true)
        }, 1000); 
        let tmp = document.location.href.slice(document.location.href?.lastIndexOf("/") , document.location.href?.length) ;        
        if(tmp === '/'  || document.location.href.includes('login') || (!document.location.search && !sessionStorage.getItem('email'))) {
            let data2 = ''; 
            sessionStorage.removeItem('returnItemData');
            sessionStorage.removeItem('selectedReasonData');
            sessionStorage.removeItem('selectedDummyReasonData');
            sessionStorage.removeItem('isSelectedLineItems');
            sessionStorage.removeItem('RPI');
            sessionStorage.removeItem('email'); 
            sessionStorage.removeItem('tabIndex');
            sessionStorage.removeItem('orderInfo');
            sessionStorage.removeItem('retailername');
            data2 = '/login';
            navigate(data2.replace(/"/g, "")); 
        }        
       },[navigate,obj.type]);
    
    return (
        <div className="hidden-overflow">
            <Navbar data={obj}></Navbar>
            {(document.location.search || sessionStorage.getItem('email')) && !(document.location.href.includes('login')) ? <Fenixorder data={JSON.parse(JSON.stringify(obj))}></Fenixorder> : <Loginportal data={JSON.parse(JSON.stringify(obj))}></Loginportal>}
            {/* <BestSellers data={obj}></BestSellers> */}
            {showDelayedFile && (
            <Footer data={obj}></Footer>
            )}
        </div>
      );
}

export default ShopSimon