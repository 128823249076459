function orderPlaced(){

    return(
        <div className="container">
            <div className="row mb-16">
                <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className='row'>
                        <div className="col-xs-4 col-sm-4 col-md-4">
                        <label className="fw-600 f-14 mt-10">10 Orders Placed in</label>
                        </div>
                        <div className="col-xs-6 col-sm-4 col-md-4">
                        
                <select className="form-select p-10 search-border f-14" aria-label="Default select example">
                <option value='Last30days'>Last 30 days</option>
                            <option value='2Months' selected>2 Months</option>
                            <option value='4Months'>4 Months</option>
                            <option value='2020'>2020</option>
                            <option value='2021'>2021</option>
                            <option value='2022'>2022</option>
                 </select> 
                 
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 text-right">
                <input type="text" className="width-80per p-10 br-5 search-border f-14" placeholder='Search all orders' />        
                </div>
            </div>
        </div>
    )

}

export default orderPlaced;