import { useEffect, useState } from "react";
import Footer from "../footter/footer";
import Navbar from "../navbar/navbar";
// import ReturnItem from "../return portal/fenix orders/retrun item/return-item";
// import ReturnedOrders from "../return portal/fenix orders/returned-orders/returned-orders";
import Loginportal from "../return portal/return-portal";
import Fenixorder from "../return portal/fenix orders/fenix-order";
import { useNavigate } from "react-router-dom";
// import PageNotFound from "../page-not-found/pageNotFound";
// import BestSellers from "../best-sellers/best-sellers";
// import MyOrders from "../return portal/fenix orders/my-orders/my-orders";



function JanieandJack(){
    const [showDelayedFile,setShowDelayedFile] = useState(false);
    const navigate = useNavigate();    

    let obj ={
        type:'janieandjack',
        fileUrl:'janieandjack/image.png',
        vendorName:'Janie and Jack',
        menuName:[{'menu':'Girl','hrefLink':'https://www.janieandjack.com/girls-clothing?lang=en_US'},{'menu':'Boy','hrefLink':'https://www.janieandjack.com/boys-clothing?lang=en_US'},{'menu':'NewBorn','hrefLink':'https://www.janieandjack.com/newborn-clothing?lang=en_US'},{'menu':'Tween','hrefLink':'https://www.janieandjack.com/shop-all-tween?lang=en_US'},{'menu':'Accessories','hrefLink':'https://www.janieandjack.com/accessories?lang=en_US'},{'menu':'Shoes','hrefLink':'https://www.janieandjack.com/shoes/childrens-shoes?lang=en_US'},{'menu':'Disney','hrefLink':'https://www.janieandjack.com/disney/shop-all?lang=en_US'},
            {'menu':'Pajamas','hrefLink':'https://www.janieandjack.com/shop-all-pajamas?lang=en_US'},{'menu':'Sale','hrefLink':'https://www.janieandjack.com/shop-all-sale?lang=en_US'}],
        login_img:'janieandjack/janieandjack.png',
        storeName:'janieandjack.com',
        tenantId:'4555cbac57524704afa53d3fe49a555b',
        footer_header:[{'header_name':'CUSTOMER SERVICE','nested_values':[{'value':'Need Help?','hrefLink':'https://www.janieandjack.com/customer-service.html?lang=en_US'},{'value':'Accessibility Statement','hrefLink':'https://www.janieandjack.com/accessibility-statement.html?lang=en_US'},{'value':'My Account','hrefLink':'https://www.janieandjack.com/sign-in?lang=en_US&rurl=1'},{'value':'Track Your Order','hrefLink':'https://www.janieandjack.com/trackorder?lang=en_US'},
            {'value':'FAQs','hrefLink':'https://www.janieandjack.com/faqs.html?lang=en_US'}]},
            {'header_name':'PROMOTIONS','nested_values':[{'value':'Special Offers','hrefLink':'https://www.janieandjack.com/promotions-coupons.html?lang=en_US'},{'value':'Refer a Friend','hrefLink':'https://www.janieandjack.com/share?lang=en_US'}]},
            {'header_name':'SHOPPING WITH US','nested_values':[{'value':'Store Locator','hrefLink':'https://www.janieandjack.com/stores?lang=en_US&showMap=true&horizontalView=true&isForm=true'},{'value':'Shipping Information','hrefLink':'https://www.janieandjack.com/shipping-information.html?lang=en_US'},{'value':'Returns','hrefLink':'https://www.janieandjack.com/returns-policy.html?lang=en_US'},{'value':'Gift Services','hrefLink':'https://www.janieandjack.com/gift-services.html?lang=en_US'},{'value':'Size Charts','hrefLink':'https://www.janieandjack.com/janieandjack-size-charts.html?lang=en_US'},{'value':'Popular Categories','hrefLink':'https://www.janieandjack.com/buy/vsm'}]},
            {'header_name':'ABOUT US','nested_values':[{'value':'Who We Are','hrefLink':'https://www.janieandjack.com/about-jj.html?lang=en_US'},{'value':'Careers','hrefLink':'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=648a76ff-b35c-49e8-80ea-6a3f6cf18843&ccId=9200358583537_2&lang=en_US'}]}
        ],
        recommendedProducts:[{'image_url':'janieandjack/janiejack-1.png','title':'Floral Flutter Sleeve','price':'$38.32'},{'image_url':'janieandjack/horsesweater.png','title':'Horse Sweater','price':'$ 59.00'},
            {'image_url':'janieandjack/frenchterryhooded.png','title':'French Terry Hooded SweatShirt','price':'$ 49.00'},{'image_url':'janieandjack/stripedslubtee.png','title':'Stripped Slub Tee','price':'$ 59.00'}
        ],
        store_url:'https://www.janieandjack.com/',
        policyUrl:'https://www.janieandjack.com/returns-policy.html?lang=en_US'
    }
    sessionStorage.setItem('retailerData', JSON.stringify(obj));

    useEffect(() => {
        setTimeout(() => {
            setShowDelayedFile(true);
        }, 1000); 
        let tmp = document.location.href.slice(document.location.href?.lastIndexOf("/") , document.location.href?.length) ;        
        if(tmp === '/'  || document.location.href.includes('login') || (!document.location.search && !sessionStorage.getItem('email'))) {
            let data2 = ''; 
            sessionStorage.removeItem('returnItemData');
            sessionStorage.removeItem('selectedReasonData');
            sessionStorage.removeItem('selectedDummyReasonData');
            sessionStorage.removeItem('isSelectedLineItems');
            sessionStorage.removeItem('RPI');
            sessionStorage.removeItem('email'); 
            sessionStorage.removeItem('tabIndex');
            sessionStorage.removeItem('orderInfo');
            sessionStorage.removeItem('retailername');
            data2 = '/login';
            navigate(data2.replace(/"/g, "")); 
        }      
       },[navigate,obj.type]);
    
    return (
        <div className="hidden-overflow">
            <Navbar data={obj}></Navbar>
            {(document.location.search || sessionStorage.getItem('email')) && !(document.location.href.includes('login')) ? <Fenixorder data={JSON.parse(JSON.stringify(obj))}></Fenixorder> : <Loginportal data={JSON.parse(JSON.stringify(obj))}></Loginportal>}
            {/* <BestSellers data={obj}></BestSellers> */}
            {showDelayedFile && (
            <Footer data={obj}></Footer>
            )}
        </div>
      );
}

export default JanieandJack